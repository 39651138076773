import React from 'react';
import {
  TeliaAccordion,
  TeliaAccordionItem,
  TeliaCol,
  TeliaList,
  TeliaRow
} from '@teliads/components/react';
import { links } from '../links';
import { useNavigate } from 'react-router-dom';
import { FooterLink } from 'components/link';
import {
  FooterNavigationWrapper,
  FooterContentMobile,
  FooterContentDesktop,
  SectionHeader
} from '../Footer.styled';

export default function FooterNavigation() {
  const navigate = useNavigate();

  const renderLinks = (links) =>
    links.map(({ name, href, to }) => (
      <TeliaList key={name}>
        <li>
          <FooterLink
            {...(to && { onClick: () => navigate(to) })}
            {...(href && { href, target: '_blank' })}
          >
            {name}
          </FooterLink>
        </li>
      </TeliaList>
    ));

  return (
    <FooterNavigationWrapper>
      <FooterContentMobile>
        <TeliaAccordion darkBackground>
          {links.map(({ header, links }) => (
            <TeliaAccordionItem
              headingTag="h2"
              heading={header}
              darkBackground
              key={header}
            >
              {renderLinks(links)}
            </TeliaAccordionItem>
          ))}
        </TeliaAccordion>
      </FooterContentMobile>
      <FooterContentDesktop>
        <TeliaRow>
          {links.map(({ header, links }) => (
            <TeliaCol width={3} key={header}>
              <SectionHeader>{header}</SectionHeader>
              {renderLinks(links)}
            </TeliaCol>
          ))}
        </TeliaRow>
      </FooterContentDesktop>
    </FooterNavigationWrapper>
  );
}
