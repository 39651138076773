import TeliaFinanceLogo from 'assets/telia-finance-logo-purple.svg';
import styled from 'styled-components';

export const WelcomeContainer = styled.div`
  padding: ${({ theme }) =>
    `${theme.spacing.spacing48} 16px ${theme.spacing.spacing24}`};

  telia-p {
    max-width: 400px;
  }
`;

export const Logo = styled.img.attrs({ src: TeliaFinanceLogo })`
  width: 160px;
  max-height: 32px;
`;

export const LoginContent = styled.div`
  @media screen and (min-width: ${({ theme }) =>
      theme.breakpoints.breakpointMd}) {
    display: grid;
    align-items: center;
    gap: ${({ theme }) =>
      `${theme.spacing.spacing0} ${theme.spacing.spacing24}`};
    grid-template-columns: 7fr 5fr;
    padding: ${({ theme }) =>
      `${theme.spacing.spacing0} ${theme.spacing.spacing24} ${theme.spacing.spacing24} ${theme.spacing.spacing0}`};
  }
`;
