import React from 'react';
import { Navigate } from 'react-router-dom';
import useGetLocalePath from 'hooks/useGetLocalePath';

import useAuth from './useAuth';

export default function AuthGuard({ children }: { children: React.ReactNode }) {
  const { token } = useAuth();
  const getLocalePath = useGetLocalePath();

  return token ? children : <Navigate to={getLocalePath('/')} />;
}
