import styled, { css } from 'styled-components';

import { Modal } from 'components/modal';

export const StyledModal = styled(Modal)`
  width: 90vw;
  overflow: hidden;

  .height-restriction {
    display: flex;
    flex-direction: column;
    max-height: 90vh;
  }
  .pdf-utility {
    margin-bottom: ${({ theme }) => theme.spacing.spacing24};
    a {
      text-decoration: none;
    }
  }

  .outline {
    overflow: auto;
    ${({ isError, theme }) =>
      !isError &&
      css`
        border: 1px solid ${theme.colors.gray500};
      `}
    margin-bottom: 40px;
  }

  .error {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    text-align: center;

    telia-icon {
      margin-bottom: ${({ theme }) => theme.spacing.spacing32};
      height: 56px;
      width: 56px;
    }
  }
`;
