import { css } from 'styled-components';

export const HoverColor = css`
  color: ${({ theme }) => theme.colors.purple500};
`;

export const HoverBorder = css`
  border-bottom: 1px solid ${({ theme }) => theme.colors.purple500};
`;

export const HoverOpacity = css`
  opacity: 100%;
`;

export const HoverStyles = css`
  ${HoverColor}
  ${HoverBorder}
  ${HoverOpacity}
`;
