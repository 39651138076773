import {CountryCodes, LanguageCodes} from 'providers/locale-provider/types';

export const termsLink = (language: LanguageCodes) => {
  const lang = languageWrapper(language);
  return `https://www.teliafinance.com/${lang}/contacts2/terms-and-conditions/`;
};

export const privacyLink = (language: LanguageCodes) => {
  const lang = languageWrapper(language);
  return `https://www.teliafinance.com/${lang}/privacy/`;
};

export const aboutUsLink = (language: LanguageCodes) => {
  const basePath = `https://www.teliafinance.com/`;

  switch (language) {
    case 'da':
      return basePath + 'dk/privat/om-os';
    case 'sv':
      return basePath + 'se/privat/om-oss';
    case 'fi':
      return basePath + 'fi/yksityisille/tietoa-meista';
    case 'nb':
      return basePath + 'no/privat/om-oss';
    case 'en':
      return basePath + 'en/private/about-us';
    default:
      return basePath + 'en/private/about-us';
  }
};

export const teliaFinanceLink = (language: LanguageCodes) => {
  const lang = languageWrapper(language);

  return `https://www.teliafinance.com/${lang}`;
};

export const privacyPolicyLink = (language: LanguageCodes) => {
  const basePath = 'https://files.teliafinance.com/';

  switch (language) {
    case 'da':
      return basePath + 'privacy-policy-dk.pdf';
    case 'sv':
      return basePath + 'privacy-policy-se.pdf';
    case 'fi':
      return basePath + 'privacy-policy-fi.pdf';
    case 'nb':
      return basePath + 'privacy-policy-no.pdf';
    case 'en':
      return basePath + 'privacy-policy-en.pdf';
    default:
      return basePath + 'privacy-policy-en.pdf';
  }
};

const languageWrapper = (language: LanguageCodes): LanguageCodes | 'dk' =>
  language === 'da' ? 'dk' : language;
