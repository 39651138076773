import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { useLocation } from 'react-router-dom';
import GtmModule from 'features/analytics';
import useLocale from 'hooks/useLocale';
import { getCustomerInformationQuery } from 'queries/customerInformationQuery';
import { useRecoilValue } from 'recoil';
import { ROUTES } from 'routes';
import Routes from 'routes/routes';
import { missingContactDetailsAtom } from 'state/atom/missing-contact-details';
import { shouldRenderActivityTimer } from 'utils/shouldRenderActivityTimer';

import ActivityTimer from 'components/ActivityTimer';
import { currentModal, ModalRoot, useModalController } from 'components/modal';
import ScrollToTop from 'components/ScrollToTop';

import 'i18n';

import GlobalStyle from './theme/GlobalStyle';

const activityTimerBlacklist = [
  ROUTES.LOGIN,
  ROUTES.NOT_A_CUSTOMER,
  ROUTES.COOKIE_INFO,
  ROUTES.USER_TERMS,
  ROUTES.SELF_CLOSE,
  ROUTES.VERIFY_EMAIL
];

function App() {
  const { i18n } = useTranslation();
  const { localeCode } = useLocale();
  const { pathname } = useLocation();
  const currModal = useRecoilValue(currentModal);
  const { open } = useModalController();
  const { data: customerInfo } = useQuery(getCustomerInformationQuery());

  const isMissingContactDetails = useRecoilValue(missingContactDetailsAtom);

  useEffect(() => {
    if (!isMissingContactDetails || !customerInfo) return;

    open('contactInformationModal', { customerInfo });
  }, [customerInfo, isMissingContactDetails]);

  useEffect(() => {
    if (!localeCode) {
      i18n.changeLanguage('en-sv');
      return;
    }
    i18n.changeLanguage(localeCode);
  }, [localeCode]);

  const activityTimerCheck: boolean = useMemo(
    () =>
      shouldRenderActivityTimer({
        activityTimerBlacklist,
        localeCode,
        pathname
      }),
    [pathname, localeCode]
  );

  const activeModal: boolean = !!currModal;
  return (
    <>
      <GlobalStyle activeModal={activeModal} />
      <ScrollToTop />

      <Routes />
      <ModalRoot />
      <GtmModule />

      {!activityTimerCheck && <ActivityTimer />}
      <ReactQueryDevtools initialIsOpen={false} />
    </>
  );
}

export default App;
