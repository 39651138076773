import React from 'react';
import { Outlet } from 'react-router-dom';
import TeliaLargeBackground from 'assets/background/Telia_Simplified_Deep_Purple_RGB_large.webp';
import TeliaMediumBackground from 'assets/background/Telia_Simplified_Deep_Purple_RGB_medium.webp';
import TeliaSmallBackground from 'assets/background/Telia_Simplified_Deep_Purple_RGB_small.webp';
import { Logo } from 'pages/login/LoginPage.styles';
import styled from 'styled-components';

import Footer from 'components/footer/Footer';
import LanguageSelector from 'components/language-selector/LanguageSelector';

import { Footer as FooterWrapper, Layout, Main, Nav } from './layout.styled';

const LoginMainBackgroundImg = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;

  & img {
    width: 100%;
    height: 100%;
  }
`;

const StyledLayout = styled(Layout)`
  background-color: #2a003e;
  ${Nav} {
    margin-top: ${({ theme }) => theme.spacing.spacing16};
    @media screen and (min-width: ${({ theme }) =>
        theme.breakpoints.breakpointMd}) {
      margin-top: ${({ theme }) => theme.spacing.spacing24};
    }

    @media screen and (min-width: ${({ theme }) =>
        theme.localTheme.breakpoints.breakpointLg}) {
      margin-top: ${({ theme }) => theme.spacing.spacing48};
    }
  }
`;

const Wrapper = styled.div`
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  flex: 1;

  ${Nav}, ${Main} {
    background: transparent;
    position: relative;
    z-index: 2;
  }
  ${LoginMainBackgroundImg} {
    z-index: 1;
  }
`;

const FlexBetween = styled.div`
  display: flex;
  justify-content: space-between;
`;

export default function VerifyEmailLayout() {
  return (
    <StyledLayout>
      <Wrapper>
        <LoginMainBackgroundImg>
          <img
            srcSet={`${TeliaLargeBackground} 1920w, ${TeliaMediumBackground} 1280w, ${TeliaSmallBackground} 640w`}
            sizes="(min-width: 1024px) 1920px, (min-width: 800px) 1280px, calc(100vw - 36px)"
            src={TeliaLargeBackground}
            alt={''}
          />
        </LoginMainBackgroundImg>
        <Nav>
          <FlexBetween>
            <Logo />

            <LanguageSelector theme="dark" className="language-selector" />
          </FlexBetween>
        </Nav>

        <Main>
          <Outlet />
        </Main>
      </Wrapper>

      <FooterWrapper>
        <Footer />
      </FooterWrapper>
    </StyledLayout>
  );
}
