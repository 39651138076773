import styled from 'styled-components';

import Modal from 'components/modal/Modal';

export const StyledModal = styled(Modal)`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: 430px;

  .successIcon {
    telia-icon {
      margin-bottom: ${({ theme }) => theme.spacing.spacing24};
      height: 56px;
      width: 56px;
    }
  }

  @media screen and (min-width: ${({ theme }) =>
      theme.breakpoints.breakpointLg}) {
    .successIcon {
      telia-icon {
        margin-bottom: ${({ theme }) => theme.spacing.spacing48};
      }
    }
  }
`;
