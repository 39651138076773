import React from 'react';

import { TeliaDivider } from '@teliads/components/react';

import styled from 'styled-components';

import { MenuLink } from 'components/link';

import useGetLocalePath from 'hooks/useGetLocalePath';
import { useBreakpoint } from 'providers/BreakpointProvider';

import useNavRoutes from './useNavRoutes';

export const SubMenuLinksWrapper = styled.div`
  margin: ${({ theme }) => `${theme.spacing.spacing16} 0`};
  display: flex;

  & > * {
    margin-right: ${({ theme }) => theme.spacing.spacing24};
  }
`;

export default function SecondaryMenu() {
  const getLocalePath = useGetLocalePath();
  const breakpoints = useBreakpoint();

  const { subRoutes } = useNavRoutes();

  if (!breakpoints.localLarge) return null;

  if (!subRoutes) return null;

  return (
    <div>
      <TeliaDivider />
      <SubMenuLinksWrapper>
        {subRoutes?.map((subLink) => (
          <MenuLink
            data-cy="subMenuRow"
            to={getLocalePath(subLink.to)}
            key={subLink.to}
          >
            {subLink.text}
          </MenuLink>
        ))}
      </SubMenuLinksWrapper>
    </div>
  );
}
