import React, { createContext, ReactNode, useEffect } from 'react';
import { useQueryClient } from 'react-query';
import { useLocation, useNavigate } from 'react-router-dom';
import { authRefresh } from 'api/auth';
import useLocalStorage from 'hooks/useLocalStorage';
import jwt_decode from 'jwt-decode';
import { CountryCodes } from 'providers/locale-provider/types';
import { getValidLocaleMatch } from 'utils/localization/getLocaleMatch';

import { getRefreshInterval } from './AuthHelpers';
import { AuthProviderContext, AuthState, TokenPayload } from './types';

export const AUTH_KEY: string = 'auth';

const AuthContext = createContext<AuthProviderContext | undefined>(undefined);

function AuthProvider({ children }: { children: ReactNode }) {
  const [authState, setAuthState] = useLocalStorage<AuthState>(
    AUTH_KEY,
    {} as AuthState
  );

  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const { pathname } = useLocation();

  const signIn = ({
    token,
    expiresAt,
    expiresIn
  }: Omit<AuthState, 'country'>): void => {
    const { country } = jwt_decode<TokenPayload>(token);
    setAuthState({
      token,
      expiresAt,
      expiresIn,
      country: country.toLowerCase() as CountryCodes
    });
    const localePathname = getValidLocaleMatch(pathname);
    navigate(`${localePathname}/home`, { replace: true });
  };

  const signOut = (
    { redirectTo }: { redirectTo?: string } = { redirectTo: '/' }
  ): void => {
    setAuthState({} as AuthState);
    queryClient.removeQueries();
    const localePathname = getValidLocaleMatch(pathname);
    navigate(`/${localePathname}${redirectTo}`, { replace: true });
  };

  useEffect(() => {
    if (!authState || !authState.expiresAt) return;

    const refreshIn = getRefreshInterval(authState.expiresAt);
    const refreshTimeout = setTimeout(async () => {
      try {
        const response = await authRefresh(authState?.token);
        const { accessToken, expiresAt, expiresIn } = response ?? {};

        if (accessToken && expiresAt && expiresIn) {
          const { country } = jwt_decode<TokenPayload>(accessToken);
          setAuthState({
            token: accessToken,
            expiresAt,
            expiresIn,
            country: country.toLowerCase() as CountryCodes
          });
        }
      } catch (e) {
        return signOut();
      }
    }, refreshIn);

    return () => clearTimeout(refreshTimeout);
  }, [authState && authState.expiresAt]);

  return (
    <AuthContext.Provider value={{ ...authState, signIn, signOut }}>
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
