import { PhoneNumberUtil } from 'google-libphonenumber';

const validatePhoneNumber = ({ phoneNumber = '', countryCode = 'SE', t }) => {
  const errorMessage = t('formLabel.invalid_phone_number', {
    defaultValue: 'Invalid phone number'
  });

  let phoneUtil = PhoneNumberUtil.getInstance();
  if (phoneUtil.isPossibleNumberString(phoneNumber, countryCode)) {
    if (!phoneUtil.isValidNumber(phoneUtil.parse(phoneNumber, countryCode))) {
      return errorMessage;
    }
    return true;
  } else {
    return errorMessage;
  }
};

export default validatePhoneNumber;
