import React from 'react';

import FooterLegal from './components/FooterLegal';
import FooterLogoBanner from './components/FooterLogoBanner';
import FooterNavigation from './components/FooterNavigation';
import { Divider, FooterWrapper } from './Footer.styled';

export default function Footer({
  includeNavigation
}: {
  includeNavigation?: boolean;
}) {
  return (
    <FooterWrapper>
      <FooterLogoBanner />
      {includeNavigation && (
        <>
          <FooterNavigation />
          <Divider />
        </>
      )}
      <FooterLegal />
    </FooterWrapper>
  );
}
