import styled, { css } from 'styled-components';

export const InjectColor = styled.span`
  ${(p) =>
    p.color &&
    css`
      color: ${p.color};
    `}
`;

export const Children = styled.div`
  display: flex;
  align-items: center;
`;
