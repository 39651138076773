import React, { useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { TeliaHeading, TeliaP } from '@teliads/components/react';
import { CustomerInformation } from 'api/customer-information/types';
import useLocale from 'hooks/useLocale';
import { useUpdateCustomerInformation } from 'hooks/useUpdateCustomerInformation';
import {
  ContactDetailsFormValues,
  FormStatus
} from 'pages/settings/components/contact-details/types';
import { useSetRecoilState } from 'recoil';
import { missingContactDetailsAtom } from 'state/atom/missing-contact-details';
import { getFormLabelTranslation } from 'utils/getFormLabelTranslation';
import validateEmail from 'utils/validation/validateEmail';
import validatePhoneNumber from 'utils/validation/validatePhoneNumber';

import { registerModal } from 'components/modal';
import TextInput from 'components/new-form/TextInput';
import Spacing from 'components/Spacing';

import ButtonWrapper from './components/ButtonWrapper';
import FormNotification from './components/FormNotification';
import { StyledModal } from './ContactInformationModal.styled';

const ContactInformationModal = ({
  customerInfo,
  closeModal
}: {
  customerInfo: CustomerInformation;
  closeModal: () => void;
}) => {
  const { t } = useTranslation();
  const { countryCode } = useLocale();

  const setIsMissingContactDetails = useSetRecoilState(
    missingContactDetailsAtom
  );
  const [formStatus, setFormStatus] = useState<FormStatus>({
    isEmailVerified: customerInfo.isEmailVerified,
    isPhoneNumberVerified: customerInfo.isPhoneNumberVerified,
    verificationEmailSentError: false
  });

  const {
    handleSubmit,
    control,
    formState: { isValid }
  } = useForm<ContactDetailsFormValues>({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    defaultValues: { email: customerInfo.email, mobile: customerInfo.mobile }
  });

  const { mutateAsync, isLoading, isError, isSuccess } =
    useUpdateCustomerInformation();

  const submitWrapper: SubmitHandler<ContactDetailsFormValues> = async (
    formData
  ) => {
    try {
      await mutateAsync({ formData, forceSendEmail: true });

      if (!customerInfo.isEmailVerified) {
        setFormStatus((prevState) => ({
          ...prevState,
          verificationEmailSent: true
        }));
      }
    } catch (e) {
      return;
    } finally {
      setTimeout(() => {
        setIsMissingContactDetails(false);
        closeModal();
      }, 3000);
    }
  };

  return (
    <StyledModal hideClose data-cy="contactInformationModal">
      <TeliaHeading tag="h3" variant="title-300">
        {t('contact_details_modal.contact_details', {
          defaultValue: 'Contact details'
        })}
      </TeliaHeading>

      <TeliaP variant="paragraph-100">
        {t('contact_details_modal.contact_details.description', {
          defaultValue:
            'Update your contact details so we can reach you when needed.'
        })}
      </TeliaP>

      <div className="formWrapper">
        <form
          onSubmit={handleSubmit(submitWrapper)}
          id="contact-information-notice-form"
        >
          <TextInput
            name="email"
            data-cy="contactDetails-email"
            label={getFormLabelTranslation({ t, inputName: 'email' })}
            control={control}
            rules={{ validate: (value) => validateEmail({ email: value, t }) }}
            inputMode="email"
          />
          <TextInput
            name="mobile"
            data-cy="contactDetails-mobile"
            label={getFormLabelTranslation({ t, inputName: 'mobile' })}
            control={control}
            rules={{
              validate: (value) =>
                validatePhoneNumber({ phoneNumber: value, countryCode, t })
            }}
            inputMode="tel"
          />
          <Spacing mt="32">
            <ButtonWrapper
              isLoading={isLoading}
              isValid={isValid}
              isSuccess={isSuccess}
            />
          </Spacing>
        </form>
        <FormNotification formStatus={formStatus} isError={isError} />
      </div>
    </StyledModal>
  );
};

registerModal('contactInformationModal', ContactInformationModal);
