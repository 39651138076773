export default {
  breakpoints: {
    breakpointLg: '77.5rem',
    breakpointXl: '96rem'
  },
  maxScreenSize: '90rem',
  textOpacityHigh: '87%',
  textOpacityMedium: '60%',
  spacing: {
    mainDesktopSpacing: '115px'
  }
};
