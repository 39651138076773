/* 
  {
    to: Link to an internal page,
    href: Link to an external page,
    name: Text to display
  }
*/

export const links = [
  {
    header: 'About Telia',
    links: [
      {
        to: '/my-account',
        name: 'Press and news'
      },
      {
        href: 'https://www.teliacompany.com/en/about-the-company/updates/',
        name: 'Digital Nation'
      },
      {
        to: '#',
        name: 'Network of the future'
      },
      {
        to: '#',
        name: 'Work for us'
      },
      {
        to: '#',
        name: 'Sustainability'
      },
      {
        to: '#',
        name: 'Board of directors'
      }
    ]
  },
  {
    header: 'Support',
    links: [
      {
        to: '#',
        name: 'Status of services'
      },
      {
        to: '#',
        name: 'Coverage maps'
      },
      {
        to: '#',
        name: 'Reporting and violations'
      },
      {
        to: '#',
        name: 'Help with invoices'
      },
      {
        to: '#',
        name: 'Community'
      }
    ]
  },
  {
    header: 'Purchase information',
    links: [
      {
        to: '#',
        name: 'Delivery'
      },
      {
        to: '#',
        name: 'Payment options'
      },
      {
        to: '#',
        name: 'Auto-pay & e-Invoice'
      },
      {
        to: '#',
        name: 'Contract transfers'
      },
      {
        to: '#',
        name: 'General terms'
      },
      {
        to: '#',
        name: 'Prices'
      }
    ]
  },
  {
    header: 'Contact',
    links: [
      {
        to: '#',
        name: 'Stores and retailers'
      },
      {
        to: '#',
        name: 'For property owners'
      },
      {
        to: '#',
        name: 'Our network and cables'
      },
      {
        to: '#',
        name: 'Email'
      }
    ]
  }
];
