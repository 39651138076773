import styled from 'styled-components';

export const StyledButtonLoadingComponent = styled.div`
  position: relative;

  span {
    opacity: 0;
  }

  .spinner-wrapper {
    opacity: 1;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    & > *::before {
      border-color: ${({ theme }) => theme.colors.gray300};
    }
  }
`;
