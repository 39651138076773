import i18n from 'i18n';

type IValidationMessages = {
  required: string;
  invalidZipCode: string;
  invalidStreetname: string;
  invalidCityName: string;
};

const validationMessages: IValidationMessages = {
  required: i18n.t('form_valiation.required', { defaultValue: 'Required' }),
  invalidZipCode: i18n.t('form_valiation.invalid_zip', {
    defaultValue: 'Invalid Zip Code'
  }),
  invalidStreetname: i18n.t('form_validation.invalid_street_name', {
    defaultValue: 'Invalid street name'
  }),
  invalidCityName: i18n.t('form_validation.invalid_city_name', {
    defaultValue: 'Invalid city name'
  })
};

export default validationMessages;
