import styled from 'styled-components';
import { MenuLink } from 'components/link';

export const Overlay = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 2;
`;

export const MobileOverlayWrapper = styled.div`
  background: ${({ theme }) => theme.colors.white};
`;

export const CloseCta = styled.div`
  position: absolute;
  top: 38px;
  right: 38px;
`;

export const MenuSection = styled.div`
  padding: ${({ theme }) => `${theme.spacing.spacing96} 0`};
`;

export const MenuWrapper = styled.div`
  margin: ${({ theme }) => `0 ${theme.spacing.spacing32} 20px`};
  max-width: max-content;
  & > * {
    display: block;
  }
`;

export const StyledMenuLink = styled(MenuLink)`
  font-size: 20px;
  margin-bottom: ${({ theme, $hasSubMenu }) =>
    $hasSubMenu && theme.spacing.spacing16};
`;

export const StyledSubMenuItem = styled(MenuLink)`
  margin-bottom: ${({ theme }) => theme.spacing.spacing16};
  max-width: max-content;
`;

export const LogoutOverlay = styled(MobileOverlayWrapper)`
  padding: ${({ theme }) => theme.spacing.spacing32};
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
