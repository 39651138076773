import React from 'react';
import { useController } from 'react-hook-form';

import { TeliaTextarea } from '@teliads/components/react';

export default function TextArea({
  control,
  name = '',
  rules,
  value = '',
  ...rest
}) {
  const {
    field: { onChange, ...fieldRest },
    formState: { errors }
  } = useController({ name, rules, control, defaultValue: value });
  return (
    <TeliaTextarea
      name={name}
      onInput={onChange}
      errorMessage={errors?.[name]?.message}
      {...rest}
      {...fieldRest}
    />
  );
}
