import React, { useEffect } from 'react';
import { gtag } from 'features/analytics/analytics';
import useCookies from 'hooks/useCookies';
import { CookieLevels } from 'providers/cookie-provider/types';

import { TRACKING_ACCEPTED, TRACKING_DENIED } from './constants';

export default function GtmModule() {
  const { acceptanceLevel } = useCookies();

  useEffect(() => {
    if (!JSON.parse(process.env.ENABLE_ANALYTICS as string)) return;

    const consentType = acceptanceLevel?.[CookieLevels.analytical]
      ? TRACKING_ACCEPTED
      : TRACKING_DENIED;

    const finalConsent: Consent = ['consent', 'update', consentType];

    gtag(...finalConsent);
  }, [acceptanceLevel]);

  return <></>;
}
