import React from 'react';
import { TeliaIcon } from '@teliads/components/react';
import { close } from '@teliads/icons';
import styled from 'styled-components';

import { useModalController } from './ModalController';

const StyledModal = styled.div`
  position: fixed;
  z-index: 301;
  background: white;

  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: ${({ theme }) => theme.borders.radiusSm};
  padding: ${({ theme }) =>
    `${theme.spacing.spacing12} ${theme.spacing.spacing16}`};
  min-width: 90vw;
  max-height: 90vh;
  overflow-y: auto;
  @media screen and (min-width: ${({ theme }) =>
      theme.breakpoints.breakpointMd}) {
    min-width: 500px;
    min-height: 300px;
    padding: ${({ theme }) => theme.spacing.spacing24};
  }
`;

const ModalHeader = styled.div`
  display: flex;
  justify-content: flex-end;
  position: absolute;
  right: 22px;
  top: 28px;
  telia-icon {
    cursor: pointer;
  }
`;

const ModalBody = styled.div`
  margin: 0
    ${({ theme }) =>
      `calc(${theme.spacing.spacing20} - ${theme.spacing.spacing12})`};

  @media screen and (min-width: ${({ theme }) =>
      theme.breakpoints.breakpointMd}) {
    margin: 0
      ${({ theme }) =>
        `calc(${theme.spacing.spacing48} - ${theme.spacing.spacing24})`};
  }
`;

export default function Modal({
  hideClose,
  children,
  ...rest
}: React.PropsWithChildren<{
  hideClose?: boolean;
}>) {
  const { close: closeModal } = useModalController();
  return (
    <StyledModal {...rest}>
      {!hideClose && (
        <ModalHeader>
          <TeliaIcon
            svg={close.svg}
            size={'lg'}
            onClick={() => closeModal(undefined, false)}
          />
        </ModalHeader>
      )}
      <ModalBody>{children}</ModalBody>
    </StyledModal>
  );
}
