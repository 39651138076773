import styled from 'styled-components';

import { Modal } from 'components/modal';

export const StyledModal = styled(Modal)`
  padding: ${(p) =>
    `${p.theme.spacing.spacing24} ${p.theme.spacing.spacing16} ${p.theme.spacing.spacing32}`};
  top: 10%;
  transform: translate(-50%, 0%);

  telia-heading {
    color: ${(p) => p.theme.colors.purple500};
    margin-bottom: ${(p) => p.theme.spacing.spacing12};
  }

  @media screen and (min-width: ${({ theme }) =>
      theme.breakpoints.breakpointMd}) {
    min-height: 0;
    padding: ${(p) => p.theme.spacing.spacing32};
    width: 90%;
    max-width: 960px;
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${(p) => p.theme.spacing.spacing24};
  row-gap: 16px;
  column-gap: 16px;

  @media screen and (min-width: ${({ theme }) =>
      theme.breakpoints.breakpointMd}) {
    flex-wrap: wrap;
    flex-direction: row;
    ${ButtonWrapper} {
      margin-top: ${(p) => p.theme.spacing.spacing32};
    }
  }
`;
