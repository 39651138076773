import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  TeliaHeading,
  TeliaIcon,
  TeliaP,
  TeliaTextSpacing
} from '@teliads/components/react';
import { checkCircleFilled } from '@teliads/icons';
import { useTheme } from 'styled-components';

import { InjectColor } from 'components/file-handling/files.styled';
import { registerModal } from 'components/modal/ModalController';

import { StyledModal } from './verifyEmailSuccess.styled';

function VerifyEmailSuccess() {
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <StyledModal data-cy="emailVerifySuccessModal">
      <InjectColor color={theme?.colors?.green700} className="successIcon">
        <TeliaIcon svg={checkCircleFilled.svg} size="lg" />
      </InjectColor>

      <TeliaTextSpacing>
        <TeliaHeading tag="h2" variant="title-300">
          {t('email.verification.modal.title', { defaultValue: 'Thank you' })}
        </TeliaHeading>
        <TeliaP variant="preamble-100">
          {t('email.verification.modal.description', {
            defaultValue: 'The e-mail verification was successful!'
          })}
        </TeliaP>
      </TeliaTextSpacing>
    </StyledModal>
  );
}

registerModal('verifyEmailSuccess', VerifyEmailSuccess);
