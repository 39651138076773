import React, { Suspense } from 'react';

import Loading, { LoadingContainer } from 'components/loading/Loading';

export const withLazyLoad = (LazyComponent) => {
  return (props) => (
    <Suspense
      fallback={
        <LoadingContainer>
          <Loading />
        </LoadingContainer>
      }
    >
      <LazyComponent {...props} />
    </Suspense>
  );
};
