import React from 'react';
import { Outlet } from 'react-router-dom';

import Footer from 'components/footer/Footer';
import Menu from 'components/menu/Menu';

import {
  Footer as FooterWrapper,
  LanguageWrapper,
  Layout,
  Main,
  Nav,
  StyledLanguageSelector
} from './layout.styled';

export default function CommonLayout() {
  return (
    <Layout>
      <LanguageWrapper>
        <StyledLanguageSelector className="language-selector" />
      </LanguageWrapper>

      <Nav>
        <Menu />
      </Nav>

      <Main>
        <Outlet />
      </Main>

      <FooterWrapper>
        <Footer />
      </FooterWrapper>
    </Layout>
  );
}
