import { useEffect, useState } from 'react';
import { CookieLevels } from 'providers/cookie-provider/types';

import useCookies from './useCookies';

const useCookieStorage = <T>(
  localStorageKey: string,
  cookieLevel: CookieLevels,
  defaultValue: T
): [T, React.Dispatch<React.SetStateAction<T>>] => {
  const { acceptanceLevel } = useCookies();

  const [storedValue, setStoredValue] = useState<T>(() => {
    const item = localStorage.getItem(localStorageKey);
    if (item) {
      return JSON.parse(item);
    }
    return defaultValue;
  });

  useEffect(() => {
    if (!acceptanceLevel[cookieLevel]) return;
    localStorage.setItem(localStorageKey, JSON.stringify(storedValue));
  }, [localStorageKey, storedValue, acceptanceLevel]);

  return [storedValue, setStoredValue];
};

export default useCookieStorage;
