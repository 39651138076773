import styled from 'styled-components';

import ButtonWrapper from 'components/ButtonWrapper';
import { Modal } from 'components/modal';

export const StyledModal = styled(Modal)`
  padding: ${(p) =>
    `${p.theme.spacing.spacing24} ${p.theme.spacing.spacing16} ${p.theme.spacing.spacing32}`};

  top: 5%;
  transform: translate(-50%, 0%);

  telia-heading {
    color: ${(p) => p.theme.colors.purple500};
    margin-bottom: ${(p) => p.theme.spacing.spacing12};
  }

  telia-p {
    margin-bottom: ${(p) => p.theme.spacing.spacing32};
  }

  telia-checkbox {
    margin-bottom: ${(p) => p.theme.spacing.spacing20};
  }

  ${ButtonWrapper} {
    margin-top: ${(p) => p.theme.spacing.spacing24};
  }

  .telia-checkbox__additional {
    color: ${(p) => p.theme.colors.black};
  }

  @media screen and (min-width: ${({ theme }) =>
      theme.breakpoints.breakpointMd}) {
    width: 90%;
    max-width: 960px;
    padding: ${(p) => p.theme.spacing.spacing32};
    ${ButtonWrapper} {
      margin-top: ${(p) => p.theme.spacing.spacing32};
    }
  }
`;
