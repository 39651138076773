import React from 'react';
import { useTranslation } from 'react-i18next';
import { TeliaButton, TeliaIcon } from '@teliads/components/react';
import { checkCircleFilled } from '@teliads/icons';
import styled, { useTheme } from 'styled-components';

import { ButtonLoadingComponent } from 'components/button-loading-component/ButtonLoadingComponent';
import { InjectColor } from 'components/file-handling/files.styled';

export default function ButtonWrapper({
  isSuccess,
  isLoading,
  isValid
}: {
  isSuccess: boolean;
  isLoading: boolean;
  isValid: boolean;
}) {
  const { t } = useTranslation();
  const theme = useTheme();

  if (isSuccess) {
    return (
      <Flex>
        <TeliaButton variant="primary" disabled={true} data-cy="success">
          <span>{t('save', { defaultValue: 'Save' })}</span>
        </TeliaButton>
        <InjectColor color={theme?.colors?.green700}>
          <TeliaIcon svg={checkCircleFilled.svg} size="lg" />
        </InjectColor>
      </Flex>
    );
  }
  if (isLoading) {
    return (
      <ButtonLoadingComponent>
        {t('save', { defaultValue: 'Save' })}
      </ButtonLoadingComponent>
    );
  }

  return (
    <TeliaButton variant="primary" disabled={!isValid} data-cy="save">
      <span>{t('save', { defaultValue: 'Save' })}</span>
    </TeliaButton>
  );
}

const Flex = styled.div`
  display: flex;
  align-items: center;
  &&& telia-p {
    margin-bottom: 0;
  }
  & > *:first-child {
    margin-right: 8px;
  }
`;
