import {
  borders,
  colors,
  breakpoints,
  grid,
  layers,
  motion,
  shadows,
  spacing,
  typography
} from '@teliads/components/foundations';

export default {
  borders,
  colors,
  breakpoints,
  grid,
  layers,
  motion,
  shadows,
  spacing,
  typography
};
