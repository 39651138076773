import React, { MouseEvent } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { TeliaButton, TeliaHeading, TeliaP } from '@teliads/components/react';
import useCookies from 'hooks/useCookies';
import useLocaleNavigate from 'hooks/useLocaleNavigate';
import { CookieLevels } from 'providers/cookie-provider/types';

import { TextLink } from 'components/link';
import { registerModal, useModalController } from 'components/modal';
import { IModalProps } from 'components/modal/types';

import { ButtonWrapper, StyledModal } from './CookieConsentModal.styled';

const CookieConsentModal = ({ closeModal }: IModalProps) => {
  const { t } = useTranslation();
  const navigate = useLocaleNavigate();
  const { open } = useModalController();

  const { acceptCookies } = useCookies();

  const handleNavigateAndClose = (e: MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    navigate('cookie-info');
    closeModal();
  };

  const handleAcceptAll = () => {
    acceptCookies({
      [CookieLevels.functional]: true,
      [CookieLevels.analytical]: true
    });
    closeModal();
  };

  const handleAcceptNecessary = () => {
    acceptCookies({
      [CookieLevels.functional]: false,
      [CookieLevels.analytical]: false
    });
    closeModal();
  };

  const handleCloseAndOpenCookieSettings = () => {
    closeModal();
    open('cookieSettingsModal');
  };

  return (
    <StyledModal hideClose data-cy="cookie-consent">
      <TeliaHeading variant="title-300" tag="h2">
        {t('cookie_consent.title', { defaultValue: `We use cookies` })}
      </TeliaHeading>
      <TeliaP>
        <Trans
          i18nKey="cookie_consent.description"
          defaults={`Cookies are used on the mypages.teliafinance.com website. <br/>Telia Finance would like to use functional cookies to simplify the usage of the webpage. Consenting to our use of cookies is optional and you can at any time choose to withdraw your consent. <br/>We also use necessary cookies that enable core functionality. We do not require your consent to use these cookies. \n<br/>Telia Finance is the controller for the use of cookies on the website. None of these cookies stores or uses any kind of personal information. More information about these cookies is found <a>here</a>.`}
          components={{
            a: <TextLink onClick={handleNavigateAndClose} />
          }}
        />
      </TeliaP>
      <ButtonWrapper>
        <TeliaButton
          data-cy="cookieConsent-accept"
          fullWidth
          variant="expressive"
          onClick={handleAcceptAll}
        >
          <span>
            {t('cookie_consent.accept', { defaultValue: 'Accept Cookies' })}
          </span>
        </TeliaButton>

        <TeliaButton
          data-cy="cookieConsent-accept-necessary"
          fullWidth
          variant="expressive"
          onClick={handleAcceptNecessary}
        >
          <span>
            {t('cookie_consent.accept_necessary', {
              defaultValue: 'Accept necessary cookies'
            })}
          </span>
        </TeliaButton>

        <TeliaButton
          fullWidth
          variant="secondary"
          onClick={handleCloseAndOpenCookieSettings}
          data-cy="cookieConsent-cookie-settings"
        >
          <span>{t('menu.settings', { defaultValue: 'Settings' })}</span>
        </TeliaButton>
      </ButtonWrapper>
    </StyledModal>
  );
};

registerModal('cookieConsentModal', CookieConsentModal);
