import React from 'react';
import {
  Control,
  Controller,
  FieldValues,
  Path,
  RegisterOptions
} from 'react-hook-form';
import { InputModeType } from '@teliads/components/dist/types/types/InputMode';
import { TextFieldType } from '@teliads/components/dist/types/types/InputType';
import { TeliaTextInput } from '@teliads/components/react';

export default function TextInput<
  T extends FieldValues = FieldValues,
  K extends Path<T> = Path<T>
>({
  type = 'text',
  name,
  control,
  label,
  rules,
  disabled,
  additional,
  ['data-cy']: datacy
}: {
  type?: TextFieldType;
  name: K;
  inputMode?: InputModeType;
  control: Control<T>;
  label?: string;
  disabled?: boolean;
  additional?: string;
  'data-cy'?: string;
  rules?: Omit<
    RegisterOptions<T, K>,
    'valueAsNumber' | 'valueAsDate' | 'setValueAs' | 'disabled'
  >;
}) {
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field, fieldState }) => {
        return (
          <TeliaTextInput
            data-cy={datacy}
            type={type}
            label={label}
            errorMessage={fieldState.error?.message}
            {...field}
            onInput={field.onChange}
            disabled={disabled}
            additional={additional}
          />
        );
      }}
    />
  );
}
