import styled, { css } from 'styled-components';

export const MaxWidth = css`
  & > * {
    max-width: ${({ theme }) => theme.localTheme.maxScreenSize};
    width: 100%;
  }
`;

export const MaxWidthContainer = styled.div`
  ${MaxWidth}
`;

export const FullBleedMaxWidth = css`
  & > * {
    width: 100%;
  }
`;
