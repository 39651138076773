import { TFunction } from 'react-i18next';

interface IFormLabelTranslation {
  name: string;
  street: string;
  zipCode: string;
  city: string;
  country: string;
  ssn: string;
  email: string;
  mobile: string;
}
export const getFormLabelTranslation = ({
  t,
  inputName
}: {
  t: TFunction;
  inputName: keyof IFormLabelTranslation;
}) =>
  ({
    name: t('formLabel.name', { defaultValue: 'Name' }),
    street: t('formLabel.address', { defaultValue: 'Address' }),
    zipCode: t('formLabel.zip', { defaultValue: 'Postcode' }),
    city: t('formLabel.city', { defaultValue: 'City' }),
    country: t('formLabel.country', { defaultValue: 'Country' }),
    ssn: t('formLabel.ssn', { defaultValue: 'Personal identity number' }),
    email: t('formLabel.email', { defaultValue: 'E-mail' }),
    mobile: t('formLabel.phone', { defaultValue: 'Phone' })
  }?.[inputName]);
