import styled from 'styled-components';
import { Modal } from 'components/modal';

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  telia-p {
    text-align: center;
    max-width: 323px;
  }
`;

export const StyledModal = styled(Modal)`
  max-width: 500px;
`;
