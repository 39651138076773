import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { TeliaButton, TeliaHeading, TeliaP } from '@teliads/components/react';

import Spacing from 'components/Spacing';
import Timer from 'components/timer/Timer';

import { registerModal } from '../../ModalController';
import { StyledModal, Wrapper } from './IdlePromptModal.styled';

const IdlePromptModal = ({
  reset,
  getRemainingTime,
  isPrompted,
  initialPromptTimeout,
  closeModal
}) => {
  const [remaining, setRemaining] = useState(initialPromptTimeout / 1000);
  const { t } = useTranslation();

  useEffect(() => {
    const interval = setInterval(() => {
      if (isPrompted()) {
        setRemaining(Math.ceil(getRemainingTime() / 1000));
      }
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, [getRemainingTime, isPrompted]);

  const handleClose = () => {
    reset();
    closeModal();
  };

  return (
    <StyledModal hideClose>
      <Wrapper>
        <Spacing mb="32" mt="48">
          <TeliaHeading variant="display-100" tag="h3">
            <Timer milliSeconds={remaining} />
          </TeliaHeading>
        </Spacing>
        <TeliaHeading variant="title-200" tag="h4">
          {t('activity_check.title', {
            defaultValue: `Are you still there?`
          })}
        </TeliaHeading>
        <TeliaP variant="paragraph-100">
          {t('activity_check.description', {
            defaultValue: `You’ve been inactive for 15 minutes! Would you like to resume?`
          })}
        </TeliaP>
        <Spacing mt="32" mb="48">
          <TeliaButton variant="primary" onClick={handleClose}>
            <span>
              {t('activity_check.cta', { defaultValue: `I'd like to resume` })}
            </span>
          </TeliaButton>
        </Spacing>
      </Wrapper>
    </StyledModal>
  );
};

registerModal('idlePromptModal', IdlePromptModal);
