import styled, { css } from 'styled-components';

const determineColor = ({ componentTheme, theme }) => {
  if (componentTheme === 'dark') {
    return theme.colors.white;
  }
  return theme.colors.gray700;
};
const determineActiveColor = ({ componentTheme, theme }) => {
  if (componentTheme === 'dark') {
    return theme.colors.white;
  }
  return theme.colors.purple850;
};

const determineBorderColor = ({ componentTheme, theme }) => {
  if (componentTheme === 'dark') {
    return theme.colors.white;
  }
  return theme.colors.purple500;
};

const determineHoverColor = ({ componentTheme, theme }) => {
  if (componentTheme === 'dark') {
    return theme.colors.white;
  }
  return theme.colors.purple500;
};

export const StyledLanguageSelector = styled.div``;

export const LanguageItem = styled.span`
  display: inline-block;
  width: 25px;
  margin-right: 24px;
  text-align: center;
  font-weight: ${(p) => (p.active ? 500 : 400)};
  padding-bottom: 14px;
  color: ${determineColor};

  &:last-child {
    margin-right: 0;
  }
  &:hover {
    color: ${determineHoverColor};
    cursor: pointer;
  }

  ${(p) =>
    p.active &&
    css`
      color: ${determineActiveColor};
      border-bottom: 2px solid ${determineBorderColor};
    `}
`;
