import { forceSignOut, getAuthState } from 'auth/AuthHelpers';

export const closeAccount = async (): Promise<boolean | void> => {
  const { token } = getAuthState();

  const response = await fetch(
    `${process.env.UNIPORTAL_BACKEND}/v1/web/customer/account/close`,
    {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`
      }
    }
  );

  if (response.status === 401) {
    return forceSignOut();
  }

  if (!response.ok) throw new Error();

  return true;
};
