import React from 'react';

import { useRecoilState } from 'recoil';
import styled from 'styled-components';

import { currentModal } from './modalAtom';
import { modals, useModalController } from './ModalController';

const ModalOverlay = styled.div`
  z-index: 999;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
`;

export default function ModalRoot() {
  const [currModal] = useRecoilState(currentModal);
  const { close } = useModalController();

  const Modal = modals[currModal?.modalName];

  return Modal ? (
    <ModalOverlay>
      <Modal {...{ closeModal: close, ...currModal?.data }} />
    </ModalOverlay>
  ) : null;
}
