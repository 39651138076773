export type ObjectCheck = Record<string, unknown>;

export default function compareObjectByKeys(
  baseObject: ObjectCheck,
  objectToCompare: ObjectCheck
): boolean {
  const baseObjectKeys = Object.keys(baseObject);
  const objectToCompareKeys = Object.keys(objectToCompare);

  if (baseObjectKeys.length !== objectToCompareKeys.length) return false;

  for (const key of baseObjectKeys) {
    if (!objectToCompareKeys.includes(key)) return false;
  }
  return true;
}
