import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import useCookieStorage from 'hooks/useCookieStorage';
import { CookieLevels } from 'providers/cookie-provider/types';
import { Locales } from 'providers/locale-provider/types';
import { getValidLocaleMatch } from 'utils/localization/getLocaleMatch';

export default function useLocaleCode(): {
  localeCode: Locales;
  clearLocale: React.Dispatch<React.SetStateAction<void>>;
} {
  const { pathname } = useLocation();
  const [localeCode, setLocalCode] = useCookieStorage<Locales>(
    'locale',
    CookieLevels.functional,
    '' as Locales
  );

  useEffect(() => {
    const localeCodeFromPath = getValidLocaleMatch(pathname);

    if (!localeCodeFromPath) return;

    setLocalCode(localeCodeFromPath);
  }, [pathname]);

  return { localeCode, clearLocale: () => setLocalCode('' as Locales) };
}
