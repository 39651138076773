import styled, { css } from 'styled-components';
import { FullBleedMaxWidth, MaxWidth } from 'theme/reusable-css/containerWidth';

import LanguageSelector from 'components/language-selector/LanguageSelector';

export const PageLayoutSpacing = css`
  padding: ${({ theme }) =>
    `${theme.spacing.spacing0} ${theme.spacing.spacing16}`};

  @media screen and (min-width: ${({ theme }) =>
      theme.breakpoints.breakpointMd}) {
    padding: ${({ theme }) =>
      `${theme.spacing.spacing0} ${theme.spacing.spacing24}`};
  }

  @media screen and (min-width: ${({ theme }) =>
      theme.localTheme.breakpoints.breakpointLg}) {
    padding: ${({ theme }) =>
      `${theme.spacing.spacing0} ${theme.spacing.spacing48}`};
  }
`;

export const FlexCenter = css`
  display: flex;
  justify-content: center;
`;

export const Layout = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`;
export const LanguageWrapper = styled.div`
  ${MaxWidth};
  ${PageLayoutSpacing};
  ${FlexCenter};

  .language-selector {
    padding-top: 13px;
    display: flex;
    justify-content: flex-end;
  }
`;

export const Nav = styled.div`
  ${MaxWidth};
  ${PageLayoutSpacing};
  ${FlexCenter};
  background-color: ${({ theme }) => theme.colors.white};
`;
export const Main = styled.main`
  ${MaxWidth};
  ${PageLayoutSpacing};
  ${FlexCenter};
  background-color: ${({ theme }) => theme.colors.gray50};
  flex: 1 1 auto;
`;
export const Footer = styled.div`
  ${MaxWidth};
  ${PageLayoutSpacing};
  ${FlexCenter};
  background-color: ${({ theme }) => theme.colors.purple900};
`;

export const StyledLanguageSelector = styled(LanguageSelector)`
  margin-bottom: ${({ theme }) => theme.spacing.spacing8};
`;

export const FullBleedMain = styled.main`
  ${FullBleedMaxWidth}
  ${FlexCenter};
  background-color: ${({ theme }) => theme.colors.gray50};
  flex: 1 1 auto;
`;
