import wait from 'utils/wait';

export interface IPoll<T, R = void> {
  fn: () => Promise<Response | T>;
  fnCondition: (result: Response | T) => boolean;
  delay: number;
  pollingMessage?: string | null;
  shouldParse?: boolean;
  onError?: (result: Response | T) => R;
}

export default async function poll<T>({
  fn,
  fnCondition,
  delay,
  pollingMessage = null,
  shouldParse = false,
  onError = () => {}
}: IPoll<T>): Promise<Response | T> {
  let result = await fn();

  if (shouldParse) {
    if (result instanceof Response) {
      if (!result.ok) {
        onError(result);
      }
      result = await result.json();
    }
  }

  while (fnCondition(result)) {
    await wait(pollingMessage, delay);

    result = await fn();
    if (shouldParse) {
      if (result instanceof Response) {
        if (!result.ok) {
          onError(result);
        }
        result = await result.json();
      }
    }
  }

  return result;
}
