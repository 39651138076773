import { ICookieState } from 'providers/cookie-provider/types';

type InjectGtm = {
  w?: typeof window;
  d?: typeof document;
  script?: string;
  dataLayer?: string;
  id: string;
};

const injectGtm = ({
  w = window,
  d = document,
  script = 'script',
  dataLayer = 'dataLayer',
  id
}: InjectGtm): void => {
  w[dataLayer] = w[dataLayer] || [];
  w[dataLayer].push({
    'gtm.start': new Date().getTime(),
    event: 'gtm.js'
  });

  let f = d.getElementsByTagName(script)[0];
  let scriptTag = d.createElement(script) as HTMLScriptElement;
  let dl = dataLayer != 'dataLayer' ? '&l=' + dataLayer : '';
  scriptTag.async = true;
  scriptTag.src = 'https://www.googletagmanager.com/gtm.js?id=' + id + dl;
  f.parentNode?.insertBefore(scriptTag, f);
};

const initConsent = (): void => {
  window.dataLayer = window.dataLayer || [];

  function gtag(...args: any[]) {
    window.dataLayer.push(arguments as any);
  }

  const json = localStorage.getItem('cookie_consent');

  if (json) {
    const consent: ICookieState = JSON.parse(json);

    if (consent.a) {
      gtag('consent', 'default', {
        ad_storage: 'granted',
        analytics_storage: 'granted'
      });
    } else {
      gtag('consent', 'default', {
        ad_storage: 'denied',
        analytics_storage: 'denied'
      });
    }
  }
};

const injectIframe = (id: string): void => {
  let f = document.getElementsByTagName('body')[0];
  let noScript = document.createElement('noscript');
  let iframe = document.createElement('iframe') as HTMLIFrameElement;
  iframe.src = 'https://www.googletagmanager.com/ns.html?id=' + id;
  iframe.setAttribute('width', '0');
  iframe.setAttribute('height', '0');
  iframe.setAttribute('style', 'display:none; visibility:hidden');
  noScript.appendChild(iframe);
  f.appendChild(noScript);
};

export const initGtm = (id: string): void => {
  if (!id) return;
  initConsent();
  injectGtm({ id });
  injectIframe(id);
};

export function gtag(...args: any[]): void {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push(arguments as any);
}
