const validateEmail = ({ email = '', t }) => {
  const errorMessage = t('formLabel.invalid_email', {
    defaultValue: 'Invalid email'
  });

  if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
    return errorMessage;
  }
  return true;
};

export default validateEmail;
