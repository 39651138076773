import { getAuthState } from 'auth/AuthHelpers';

export const sendFeedback = async ({
  feedback,
  panAccountNumber
}: {
  feedback: string;
  panAccountNumber: string;
}): Promise<boolean | void> => {
  const { token } = getAuthState();

  const response = await fetch(
    `${process.env.UNIPORTAL_BACKEND}/v1/web/mail/${panAccountNumber}/provide-feedback`,
    {
      method: 'POST',
      headers: {
        'Content-type': 'application/json',

        Authorization: `Bearer ${token}`
      },
      body: JSON.stringify({ message: feedback })
    }
  );

  if (!response.ok) throw new Error();

  return true;
};
