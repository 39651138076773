import React from 'react';

import { TeliaIcon } from '@teliads/components/react';
import { close } from '@teliads/icons';

import useGetLocalePath from 'hooks/useGetLocalePath';

import useNavRoutes from '../useNavRoutes';
import {
  CloseCta,
  MenuSection,
  MenuWrapper,
  MobileOverlayWrapper,
  Overlay,
  StyledMenuLink,
  StyledSubMenuItem
} from './Overlays.styled';

export default function MenuOverlay({ closeOverlay }) {
  const getLocalePath = useGetLocalePath();
  const onNavigate = () => closeOverlay();

  const { routes } = useNavRoutes();

  return (
    <Overlay>
      <MobileOverlayWrapper>
        <CloseCta onClick={closeOverlay}>
          <TeliaIcon svg={close.svg} size="lg" />
        </CloseCta>

        <MenuSection data-cy="menuMobileOverlay">
          {routes?.map((link) => (
            <MenuWrapper key={link.to}>
              <StyledMenuLink
                onClick={onNavigate}
                to={getLocalePath(link.to)}
                $hasSubMenu={!!link.subMenu}
                end
              >
                {link.text}
              </StyledMenuLink>
              {link.subMenu &&
                link.subMenu.map((subLink) => (
                  <StyledSubMenuItem
                    onClick={onNavigate}
                    to={getLocalePath(subLink.to)}
                    key={subLink.to}
                  >
                    {subLink.text}
                  </StyledSubMenuItem>
                ))}
            </MenuWrapper>
          ))}
        </MenuSection>
      </MobileOverlayWrapper>
    </Overlay>
  );
}
