import { getCustomerInformation } from 'api/customer-information';
import useAuth from 'auth/useAuth';

export const getCustomerInformationQuery = () => {
  const { token } = useAuth();

  return {
    queryKey: [getCustomerInformationQueryKey],
    queryFn: () => getCustomerInformation(),
    enabled: !!token
  };
};

export const getCustomerInformationQueryKey = 'customer-information';
