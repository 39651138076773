import { useMutation, useQueryClient } from 'react-query';
import {
  getCustomerInformation,
  updateCustomerInformation
} from 'api/customer-information';
import useAuth from 'auth/useAuth';
import { getCustomerInformationQueryKey } from 'queries/customerInformationQuery';

import { UpdatedCustomerInformation } from '../api/customer-information/types';

export const useUpdateCustomerInformation = () => {
  const queryClient = useQueryClient();
  const { token } = useAuth();

  return useMutation(
    ({
      formData,
      forceSendEmail = false
    }: {
      formData: Partial<UpdatedCustomerInformation>;
      forceSendEmail?: boolean;
    }) => {
      return updateCustomerInformation({
        updatedCustomerInfo: formData,
        token,
        forceSendEmail
      });
    },
    {
      onSuccess: async () => {
        const updatedUser = await getCustomerInformation();
        queryClient.setQueryData(getCustomerInformationQueryKey, updatedUser);
      }
    }
  );
};
