import { NavLink } from 'react-router-dom';
import styled, { css } from 'styled-components';
import {
  HoverColor,
  HoverOpacity,
  HoverStyles
} from 'theme/reusable-css/hover';

const BaseLink = css`
  text-decoration: none;
  font-weight: ${({ theme }) => theme.typography.weightMedium};
  font-size: 16px;
  letter-spacing: 0.4px;

  color: ${({ theme }) => theme.colors.black};
  opacity: 87%;
  border-bottom: 1px solid transparent;
  cursor: pointer;
`;

export const MenuLink = styled(NavLink)`
  ${BaseLink};
  width: max-content;
  &[aria-current],
  &:hover {
    ${HoverStyles}
  }
`;

export const SecondaryMenuLink = styled.div`
  ${BaseLink};
  display: flex;
  align-items: center;

  p {
    display: none;
  }

  &[aria-current],
  &:hover {
    ${HoverColor}
    ${HoverOpacity}
    p {
      ${HoverStyles}
    }
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.breakpointMd}) {
    p {
      display: initial;
      margin-left: 8px;
      border-bottom: 1px solid transparent;
    }
  }
`;

export const SecondaryMenuLogoutLink = styled(SecondaryMenuLink)`
  p {
    display: initial;
  }
`;

export const FooterLinkBase = css`
  font-size: 1rem;
  color: ${({ theme }) => theme.colors.white};
  opacity: ${({ theme }) => theme.localTheme.textOpacityHigh};
  cursor: pointer;
  text-decoration: none;
  @media screen and (min-width: ${(p) => p.theme.breakpoints.breakpointLg}) {
    font-size: 0.875rem;
  }
`;

export const FooterLink = styled.a`
  ${FooterLinkBase}
  &:hover {
    text-decoration: underline;
  }
`;

export const LegalLinks = styled.a`
  ${FooterLinkBase};
  font-weight: 700;
`;

export const TextLink = styled.a`
  ${BaseLink};
  color: ${({ theme }) => theme.colors.purple500};
  font-weight: inherit;
  font-size: inherit;
  line-height: inherit;
  &:hover {
    ${HoverStyles}
  }
`;
