import React, { createContext, useMemo } from 'react';
import useLocale from 'hooks/useLocale';

const rawFeatureFlags = process.env.FEATURE_FLAGS as string | undefined;

let features: Record<string, Record<string, boolean>> = {};

try {
  features = JSON.parse(rawFeatureFlags || '{}');
} catch (error) {
  console.error('Error parsing FEATURE_FLAGS:', error);
}

const FeatureFlags = createContext<Record<string, boolean>>({});

function FeatureFlagsProvider({ children }: { children: React.ReactNode }) {
  const { countryCode } = useLocale();

  const featuresFlags = useMemo(() => {
    const byCountry = features?.[countryCode];
    return { ...byCountry, ...features?.all };
  }, [countryCode]);

  return (
    <FeatureFlags.Provider value={featuresFlags}>
      {children}
    </FeatureFlags.Provider>
  );
}

export { FeatureFlags, FeatureFlagsProvider };
