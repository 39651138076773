import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  TeliaButton,
  TeliaHeading,
  TeliaP,
  TeliaTextSpacing
} from '@teliads/components/react';
import { closeAccount } from 'api/account';
import useAuth from 'auth/useAuth';

import ButtonWrapper from 'components/ButtonWrapper';
import { registerModal } from 'components/modal/ModalController';
import Spacing from 'components/Spacing';

import { StyledModal } from './CloseAccountModal.styled';

function closeAccountModal({ closeModal }) {
  const { t } = useTranslation();

  const { signOut } = useAuth();

  const handleCloseAccount = () => {
    closeAccount();
    closeModal();
    signOut();
  };

  return (
    <StyledModal hideClose data-cy="closeAccountModal">
      <TeliaTextSpacing>
        <TeliaHeading tag="h3" variant="title-200">
          {t('my_account_page.close_account.modal.cloa_account', {
            defaultValue: 'Close account'
          })}
        </TeliaHeading>

        <TeliaP variant="paragraph-100">
          {t('my_account_page.close_account.modal.description', {
            defaultValue:
              'Are you sure you want to close your account text placeholder here to inform what will happen.'
          })}
        </TeliaP>
      </TeliaTextSpacing>

      <Spacing mt="32">
        <ButtonWrapper>
          <TeliaButton
            variant="primary"
            onClick={handleCloseAccount}
            data-cy="closeAccount"
          >
            <span>
              {t('my_account_page.outstanding_debt.close_account', {
                defaultValue: 'Close account'
              })}
            </span>
          </TeliaButton>
          <TeliaButton
            variant="secondary"
            onClick={closeModal}
            data-cy="exitCloseAccount"
          >
            <span>
              {t('my_account_page.close_account.modal.exit.cta', {
                defaultValue: 'Exit'
              })}
            </span>
          </TeliaButton>
        </ButtonWrapper>
      </Spacing>
    </StyledModal>
  );
}

registerModal('closeAccountModal', closeAccountModal);
