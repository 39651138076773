import React from 'react';

import { TeliaFinanceIcon, IconWrapper } from '../Footer.styled';

export default function FooterLogoBanner() {
  return (
    <IconWrapper>
      <TeliaFinanceIcon />
    </IconWrapper>
  );
}
