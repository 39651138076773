export const ROUTES = {
  LOGIN: '/',
  NOT_A_CUSTOMER: '/notacustomer',
  VERIFY_EMAIL: '/verifyEmail',
  HOME: '/home',
  MY_INVOICES: '/my-invoices',
  ALL_TRANSACTIONS: '/my-invoices/all-transactions',
  PAY_EXTRA: '/my-invoices/pay-extra',
  MY_ACCOUNT: '/my-account',
  DOCUMENTS: '/documents',
  SETTINGS: '/settings',
  KYC: '/settings/kyc',
  SUPPORT: '/support',
  COOKIE_INFO: '/cookie-info',
  USER_TERMS: '/user-terms',
  SELF_CLOSE: '/self-close'
} as const;

type ObjectValues<T> = T[keyof T];

export type Routes = ObjectValues<typeof ROUTES>;
