import React from 'react';
import MenuOverlay from './MenuOverlay';
import LogoutOverlay from './LogoutOverlay';
import { OVERLAY_TYPES } from '../Menu';

export default function MobileMenuOverlay({ isOverLayOpen, setIsOverlayOpen }) {
  const closeOverlay = () => setIsOverlayOpen('');

  switch (isOverLayOpen) {
    case OVERLAY_TYPES.MENU:
      return <MenuOverlay closeOverlay={closeOverlay} />;
    case OVERLAY_TYPES.LOGOUT:
      return <LogoutOverlay closeOverlay={closeOverlay} />;
    default:
      return null;
  }
}
