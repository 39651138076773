import { AUTH_KEY } from 'auth/AuthProvider';
import ky from 'ky';

const api = ky.extend({
  prefixUrl: process.env.UNIPORTAL_BACKEND,
  retry: { limit: 0 } /* Retries should be handled by React Query */,
  hooks: {
    beforeRequest: [
      (request) => {
        /* We're prefetching some things during login where the token is not set yet, 
        therefor we need to support it to set it "manually"  */
        if (!request.headers.has('Authorization')) {
          request.headers.set(
            'Authorization',
            `Bearer ${JSON.parse(localStorage.getItem(AUTH_KEY) || '')?.token}`
          );
        }
      }
    ]
  }
});

export default api;
