export const supportedLocales = [
  'sv-se',
  'en-se',
  'sv-fi',
  'en-fi',
  'fi-fi',
  'da-dk',
  'en-no',
  'nb-no'
];

export const countrySpecificLocales = {
  se: [
    { localeCode: 'sv-se', displayCode: 'SE' },
    { localeCode: 'en-se', displayCode: 'EN' }
  ],
  fi: [
    { localeCode: 'fi-fi', displayCode: 'FI' },
    { localeCode: 'en-fi', displayCode: 'EN' },
    { localeCode: 'sv-fi', displayCode: 'SV' }
  ],
  no: [
    { localeCode: 'nb-no', displayCode: 'NB' },
    { localeCode: 'en-no', displayCode: 'EN' }
  ],
  dk: [{ localeCode: 'da-dk', displayCode: 'DA' }]
};
