import React, { createContext, ReactNode } from 'react';
import { AUTH_KEY } from 'auth/AuthProvider';
import useLocalStorage from 'hooks/useLocalStorage';

import {
  CookieConsentKey,
  CookieLevels,
  ICookieProvider,
  ICookieState
} from './types';
import compareObjectByKeys from 'utils/compareObjectByKeys';

const initialState = {
  acceptCookies: () => {},
  clearCookies: () => {},
  getShouldPromptCookies: () => false,
  acceptanceLevel: {
    [CookieLevels.notice]: true,
    [CookieLevels.functional]: false,
    [CookieLevels.analytical]: false
  }
};
const CookieContext = createContext<ICookieProvider>(initialState);
const NECESSARY_COOKIES = [AUTH_KEY, CookieConsentKey.key];

function CookieProvider({ children }: { children: ReactNode }) {
  const [acceptanceLevel, setAcceptanceLevel] = useLocalStorage<ICookieState>(
    CookieConsentKey.key,
    initialState.acceptanceLevel
  );

  const clearCookies = () => {
    setAcceptanceLevel({
      ...initialState.acceptanceLevel,
      [CookieLevels.notice]: false
    });

    Object.keys(localStorage)
      .filter((key) => !NECESSARY_COOKIES.includes(key))
      .map((keyToDelete) => localStorage.removeItem(keyToDelete));
  };

  const acceptCookies = (newAcceptanceLevel: Partial<ICookieState>): void =>
    setAcceptanceLevel((prevState: ICookieState) => ({
      ...prevState,
      ...newAcceptanceLevel,
      [CookieLevels.notice]: false
    }));

  const getShouldPromptCookies = () => {
    const isEqual = compareObjectByKeys(
      initialState.acceptanceLevel,
      acceptanceLevel
    );

    if (!isEqual) {
      return true;
    }
    return !!acceptanceLevel[CookieLevels.notice];
  };
  return (
    <CookieContext.Provider
      value={{
        acceptanceLevel,
        acceptCookies,
        clearCookies,
        getShouldPromptCookies
      }}
    >
      {children}
    </CookieContext.Provider>
  );
}

export { CookieContext, CookieProvider };
