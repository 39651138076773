import React from 'react';
import { useController } from 'react-hook-form';
import { TeliaCheckbox } from '@teliads/components/react';

export default function Checkbox({
  control,
  name,
  checked,
  children,
  ...rest
}) {
  const {
    field: { onChange, value, ...fieldRest }
  } = useController({ name, control, defaultValue: checked });
  return (
    <TeliaCheckbox
      name={name}
      checked={value}
      onInput={onChange}
      {...rest}
      {...fieldRest}
    >
      {children}
    </TeliaCheckbox>
  );
}
