import { matchPath } from 'react-router-dom';
import { CountryCodes, Locales } from 'providers/locale-provider/types';

import {
  countrySpecificLocales,
  supportedLocales
} from '../../i18n/supported-locales';

export const getLocalematch = (pathname: string): Locales | string => {
  const match = matchPath({ path: '/:lang/*' }, pathname);
  const locale = match?.params?.lang;

  return locale ? locale.toLowerCase() : '';
};

export const getValidLocaleMatch = (pathname: string): Locales | null => {
  const locale = getLocalematch(pathname);

  if (!locale) return null;

  const isValidMatch = supportedLocales.includes(locale?.toLowerCase());

  if (!isValidMatch) return null;

  return locale?.toLowerCase() as Locales;
};

export const getIsCountrySpecificValidLocalMatch = ({
  pathname,
  countryCode
}: {
  pathname: string;
  countryCode: CountryCodes;
}): boolean => {
  const locale = getLocalematch(pathname);
  if (!locale) return false;

  const isValidmatch = countrySpecificLocales?.[countryCode]?.some(
    (item: { localeCode: Locales; displayCode: string }): boolean =>
      item?.localeCode === locale
  );
  return isValidmatch;
};
