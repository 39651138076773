import { generatePath } from 'react-router-dom';

import useLocale from './useLocale';

export default function useGetLocalePath() {
  const { localeCode } = useLocale();
  return (to) => {
    const t = to.replace('/', '');

    if (localeCode) {
      return generatePath(`/:lang/${t}`, { lang: localeCode });
    }

    return t;
  };
}
