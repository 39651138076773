import styled, { css } from 'styled-components';
import TeliaFinanceLogoWhite from 'assets/telia-finance-logo-white.svg';
import { FooterLinkBase } from 'components/link';

export const FooterWrapper = styled.footer`
  margin: ${({ theme }) => theme.spacing.spacing16} 0
    ${({ theme }) => theme.spacing.spacing32};

  @media screen and (min-width: ${({ theme }) =>
      theme.breakpoints.breakpointLg}) {
    margin: ${({ theme }) => `${theme.spacing.spacing16} 0 0`};
  }
`;

export const Divider = styled.div`
  display: none;
  @media screen and (min-width: ${({ theme }) =>
      theme.breakpoints.breakpointLg}) {
    display: initial;
    height: 1px;
    background: rgba(225, 225, 225, 0.12);
  }
`;

//Footer logo banner
export const IconWrapper = styled.div`
  color: ${({ theme }) => theme.colors.white};

  > * {
    &:first-child {
      margin-bottom: ${({ theme }) => theme.spacing.spacing32};
    }
  }

  @media screen and (min-width: ${({ theme }) =>
      theme.breakpoints.breakpointLg}) {
    > * {
      &:first-child {
        margin-bottom: 0;
      }
    }
  }
`;

export const TeliaFinanceIcon = styled.img.attrs({
  src: TeliaFinanceLogoWhite
})`
  max-width: 160px;
  max-height: 32px;
`;

//Footer navigation
export const FooterNavigationWrapper = styled.div`
  display: block;
  margin-bottom: ${({ theme }) => theme.spacing.spacing64};

  @media screen and (min-width: ${({ theme }) =>
      theme.breakpoints.breakpointLg}) {
    margin-bottom: ${({ theme }) => `calc(${theme.spacing.spacing128} - 1rem)`};
    margin-top: ${({ theme }) => theme.spacing.spacing48};
  }
`;

export const VocaNavStyles = css`
  telia-col:nth-child(n + 5) {
    margin-top: ${({ theme }) => theme.spacing.spacing48};
  }

  telia-list li {
    margin-bottom: 32px;
    @media screen and (min-width: ${({ theme }) =>
        theme.breakpoints.breakpointLg}) {
      margin-bottom: 1rem;
    }
  }
`;

export const FooterContentMobile = styled.section`
  ${VocaNavStyles};
  @media screen and (min-width: ${({ theme }) =>
      theme.breakpoints.breakpointLg}) {
    display: none;
  }
`;
export const FooterContentDesktop = styled.section`
  display: none;
  ${VocaNavStyles};

  @media screen and (min-width: ${({ theme }) =>
      theme.breakpoints.breakpointLg}) {
    display: block;
  }
`;
export const SectionHeader = styled.h2`
  ${FooterLinkBase}
  margin-bottom:${({ theme }) => theme.spacing.spacing24};
  font-weight: 700;
  cursor: default;
`;

//Footer Legal
export const FooterLegalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  color: ${({ theme }) => theme.colors.white};
  font-size: 0.875rem;

  .copyright {
    opacity: ${({ theme }) => theme.localTheme.textOpacityMedium};
    order: 2;
  }

  @media screen and (min-width: ${({ theme }) =>
      theme.breakpoints.breakpointLg}) {
    height: 70px;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
`;

export const FooterLinksWrapper = styled.div`
  display: flex;
  flex-direction: column;
  order: 1;
  line-height: 32px;

  @media screen and (min-width: ${({ theme }) =>
      theme.breakpoints.breakpointLg}) {
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    order: 3;
    line-height: 24px;
  }

  & > * {
    margin-bottom: ${({ theme }) => theme.spacing.spacing16};
    &:last-child {
      margin-bottom: ${({ theme }) => theme.spacing.spacing80};
    }

    @media screen and (min-width: ${({ theme }) =>
        theme.breakpoints.breakpointLg}) {
      margin: 0;
      margin-right: ${({ theme }) => theme.spacing.spacing24};
      &:last-child {
        margin: 0;
      }
    }
  }
`;
