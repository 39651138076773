import React from 'react';
import { TeliaButton } from '@teliads/components/react';

import Spinner from 'components/spinner/Spinner';

import { StyledButtonLoadingComponent } from './StyledButtonLoadingComponent';

type Props = {
  fullWidth?: boolean;
  children: React.ReactNode;
};

export const ButtonLoadingComponent = ({ fullWidth, children }: Props) => {
  return (
    <StyledButtonLoadingComponent>
      <TeliaButton
        variant="primary"
        disabled
        type="button"
        fullWidth={fullWidth}
      >
        <span>{children}</span>
        <span className="spinner-wrapper">
          <Spinner size={24} />
        </span>
      </TeliaButton>
    </StyledButtonLoadingComponent>
  );
};
