import { useEffect, useState } from 'react';
import { TFunction, useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useLocation } from 'react-router-dom';
import { CustomerInformation } from 'api/customer-information/types';
import useFeatureFlag from 'hooks/useFeatureFlag';
import { getCustomerInformationQuery } from 'queries/customerInformationQuery';

type GetSubRoutes = { to: string; linkName: string; text: string }[] | false;
type GetRoutes = {
  to: string;
  linkName: string;
  text: string;
  subMenu: GetSubRoutes;
}[];

export const getNavigationRoutes = ({
  t,
  customerInfo,
  enablePayments,
  pathname
}: {
  t: TFunction;
  customerInfo: CustomerInformation;
  enablePayments: boolean;
  pathname: string;
}) => {
  const routes = getRoutes(t);

  const exclusionList = getExclusionSet(customerInfo, enablePayments);
  return {
    filteredRoutes: getFilteredRoutes(exclusionList, routes),
    filteredSubRoutes: getFilteredSubMenu(exclusionList, pathname, routes)
  };
};

export default function useNavRoutes() {
  const { pathname } = useLocation();
  const { t } = useTranslation();
  const { enablePayments } = useFeatureFlag();
  const [routes, setRoutes] = useState<GetRoutes>();
  const [subRoutes, setSubRoutes] = useState<GetSubRoutes>();

  const { data: customerInfo } = useQuery(getCustomerInformationQuery());

  useEffect(() => {
    const { filteredRoutes, filteredSubRoutes } = getNavigationRoutes({
      t,
      customerInfo: customerInfo as CustomerInformation,
      enablePayments,
      pathname
    });
    setRoutes(filteredRoutes);
    setSubRoutes(filteredSubRoutes);
  }, [customerInfo, enablePayments, t, pathname]);

  return { routes, subRoutes };
}

const getExclusionSet = (
  customerInfo: CustomerInformation,
  enablePayments: boolean
) => {
  const exclusionList: string[] = [];
  if (!enablePayments || !(customerInfo?.currentDebt > 0)) {
    exclusionList.push('payextra');
  }
  return exclusionList;
};

const getFilteredRoutes = (exclusionList: string[], routes: GetRoutes) => {
  return routes
    .filter((rt) => !exclusionList.includes(rt.linkName || ''))
    .map((route) => ({
      ...route
    }));
};

const getFilteredSubMenu = (
  exclusionList: string[],
  basePath: string,
  routes: GetRoutes
) => {
  return routes
    .map((route) => ({
      to: route.to,
      text: route.text,
      subMenu:
        route.subMenu &&
        route.subMenu.filter((sub) => !exclusionList.includes(sub.linkName))
    }))
    .find((route) => basePath.includes(route.to))?.subMenu;
};

const getRoutes = (t: TFunction): GetRoutes => [
  {
    to: '/home',
    linkName: 'home',
    text: t('menu.home', { defaultValue: 'Home' }),
    subMenu: false
  },
  {
    to: '/my-invoices',
    linkName: 'myinvoices',
    text: t('menu.my-invoices', { defaultValue: 'My invoices' }),
    subMenu: [
      {
        to: '/my-invoices/all-transactions',
        linkName: 'alltransactions',
        text: t('menu.all-transactions', {
          defaultValue: 'All transactions'
        })
      },
      {
        to: '/my-invoices/pay-extra',
        linkName: 'payextra',
        text: t('menu.pay-extra', { defaultValue: 'Pay extra' })
      }
    ]
  },
  {
    to: '/my-account',
    linkName: 'myaccount',
    text: t('menu.my-account', { defaultValue: 'My account' }),
    subMenu: false
  },
  {
    to: '/settings',
    linkName: 'settings',
    text: t('menu.settings', { defaultValue: 'Settings' }),
    subMenu: false
  },
  {
    to: '/documents',
    linkName: 'documents',
    text: t('menu.documents', { defaultValue: 'Documents' }),
    subMenu: false
  },
  {
    to: '/support',
    linkName: 'support',
    text: t('menu.support', { defaultValue: 'Support' }),
    subMenu: false
  }
];
