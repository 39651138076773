export enum CookieLevels {
  notice = 't',
  functional = 'f',
  analytical = 'a'
}

export enum CookieConsentKey {
  key = 'cookie_consent'
}

export type ICookieState = {
  [CookieLevels.notice]: boolean;
  [CookieLevels.functional]: boolean;
  [CookieLevels.analytical]: boolean;
};

export interface ICookieProvider {
  acceptanceLevel: ICookieState;
  acceptCookies: (a: Partial<ICookieState>) => void;
  clearCookies: () => void;
  getShouldPromptCookies: () => boolean;
}
