import styled, { css } from 'styled-components';

export const MessageWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 415px;
  border-radius: 12px;
  margin-left: ${({ theme }) => theme.spacing.spacing32};
  margin-right: ${({ theme }) => theme.spacing.spacing32};
  text-align: center;

  telia-icon {
    margin-bottom: ${({ theme }) => theme.spacing.spacing32};
    height: 56px;
    width: 56px;
  }
`;

export const TextAreaNoResize = styled.div`
  textarea {
    resize: none;
  }
`;
