import { Locales } from 'providers/locale-provider/types';

export interface IShouldRenderActivityTimer {
  activityTimerBlacklist: string[];
  localeCode: Locales;
  pathname: string;
}
export const shouldRenderActivityTimer = ({
  activityTimerBlacklist,
  localeCode,
  pathname
}: IShouldRenderActivityTimer) => {
  return activityTimerBlacklist.some((entry) => {
    const localizedEntry = `/${localeCode}${entry}`;
    const formattedLocalizedEntry = localizedEntry.endsWith('/')
      ? localizedEntry.slice(0, -1)
      : localizedEntry;

    return formattedLocalizedEntry === pathname;
  });
};
