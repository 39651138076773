import React from 'react';
import { useTranslation } from 'react-i18next';
import { TeliaNotification } from '@teliads/components/react';
import useFeatureFlag from 'hooks/useFeatureFlag';
import { FormStatus } from 'pages/settings/components/contact-details/types';

import Spacing from 'components/Spacing';

export default function FormNotification({
  formStatus,
  isError
}: {
  formStatus: FormStatus;
  isError: boolean;
}) {
  const { t } = useTranslation();
  const { emailVerification } = useFeatureFlag();
  if (isError) {
    return (
      <Spacing mt="24">
        <TeliaNotification
          status="error"
          headingTag="h6"
          data-cy="contactDetails-error"
        >
          <span slot="heading">
            {t('form-feedback.could-not-save-information', {
              defaultValue: 'Could not save the information'
            })}
          </span>
        </TeliaNotification>
      </Spacing>
    );
  }

  if (formStatus.verificationEmailSent && emailVerification) {
    return (
      <Spacing mt="24">
        <TeliaNotification
          status="information"
          headingTag="h6"
          data-cy="verifyEmailSentNotification"
        >
          <span slot="heading">
            {t('verification_email_sent', {
              defaultValue: 'Verification email sent'
            })}
          </span>
        </TeliaNotification>
      </Spacing>
    );
  }

  return null;
}
