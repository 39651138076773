import styled, { css } from 'styled-components';

const Spacing = styled.div`
  ${({ theme, mt = 0, mr = 0, mb = 0, ml = 0 }) =>
    css`
      margin: ${theme.spacing[`spacing${mt}`]} ${theme.spacing[`spacing${mr}`]}
        ${theme.spacing[`spacing${mb}`]} ${theme.spacing[`spacing${ml}`]};
    `}

  ${({ theme, pt = 0, pr = 0, pb = 0, pl = 0 }) =>
    css`
      padding: ${theme.spacing[`spacing${pt}`]} ${theme.spacing[`spacing${pr}`]}
        ${theme.spacing[`spacing${pb}`]} ${theme.spacing[`spacing${pl}`]};
    `}


    @media screen and (min-width: ${({ theme }) =>
    theme.breakpoints.breakpointMd}) {
    ${({ theme, tablet = {}, mt = 0, mr = 0, mb = 0, ml = 0 }) => {
      const tabletSpacing = { ...{ mt, mr, mb, ml }, ...tablet };

      return css`
        margin: ${theme.spacing[`spacing${tabletSpacing?.mt}`]}
          ${theme.spacing[`spacing${tabletSpacing?.mr}`]}
          ${theme.spacing[`spacing${tabletSpacing?.mb}`]}
          ${theme.spacing[`spacing${tabletSpacing?.ml}`]};
      `;
    }}

    ${({ theme, tablet = {}, pt = 0, pr = 0, pb = 0, pl = 0 }) => {
      const tabletSpacing = { ...{ pt, pr, pb, pl }, ...tablet };

      return css`
        padding: ${theme.spacing[`spacing${tabletSpacing?.pt}`]}
          ${theme.spacing[`spacing${tabletSpacing?.pr}`]}
          ${theme.spacing[`spacing${tabletSpacing?.pb}`]}
          ${theme.spacing[`spacing${tabletSpacing?.pl}`]};
      `;
    }}
  }
  @media screen and (min-width: ${({ theme }) =>
      theme.breakpoints.breakpointLg}) {
    ${({
      theme,
      desktop = {},
      tablet = {},
      mt = 0,
      mr = 0,
      mb = 0,
      ml = 0
    }) => {
      const desktopSpacing = { ...{ mt, mr, mb, ml }, ...tablet, ...desktop };

      return css`
        margin: ${theme.spacing[`spacing${desktopSpacing?.mt}`]}
          ${theme.spacing[`spacing${desktopSpacing?.mr}`]}
          ${theme.spacing[`spacing${desktopSpacing?.mb}`]}
          ${theme.spacing[`spacing${desktopSpacing?.ml}`]};
      `;
    }}

    ${({ theme, desktop = {}, tablet, pt = 0, pr = 0, pb = 0, pl = 0 }) => {
      const desktopSpacing = { ...{ pt, pr, pb, pl }, ...tablet, ...desktop };
      return css`
        padding: ${theme.spacing[`spacing${desktopSpacing?.pt}`]}
          ${theme.spacing[`spacing${desktopSpacing?.pr}`]}
          ${theme.spacing[`spacing${desktopSpacing?.pb}`]}
          ${theme.spacing[`spacing${desktopSpacing?.pl}`]};
      `;
    }}
  }
`;

export default Spacing;
