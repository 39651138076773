import api from 'api/api';
import { forceSignOut } from 'auth/AuthHelpers';
import { ROUTES } from 'routes';
import { getLocalematch } from 'utils/localization/getLocaleMatch';

import { CustomerInformation, UpdatedCustomerInformation } from './types';

export const getCustomerInformation = async (token?: string) => {
  const response = await api.get<CustomerInformation>('v1/web/customer', {
    ...(token && { headers: { Authorization: `Bearer ${token}` } }),
    throwHttpErrors: false
  });

  if (response.status === 401) {
    return forceSignOut();
  }

  if (response.status === 404) {
    throw new NotACustomerError(response);
  }

  const user = await response.json();
  // Account status is either ACTIVE, CLOSED, CLOSING, BLOCKED

  if (!['ACTIVE', 'BLOCKED'].includes(user?.accountStatus)) {
    throw new NotACustomerError(response);
  }

  return user;
};

export const updateCustomerInformation = async ({
  updatedCustomerInfo,
  token,
  forceSendEmail = false
}: {
  updatedCustomerInfo: Partial<CustomerInformation>;
  token: string;
  forceSendEmail?: boolean;
}): Promise<UpdatedCustomerInformation | void> => {
  const redirectUrl = encodeURIComponent(
    `${window.location.origin}/${getLocalematch(window.location.pathname)}${
      ROUTES.VERIFY_EMAIL
    }`
  );

  const response = await fetch(
    `${process.env.UNIPORTAL_BACKEND}/v1/web/customer?verificationUrl=${redirectUrl}&forceSendEmail=${forceSendEmail}`,
    {
      method: 'PATCH',
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${token}`
      },
      body: JSON.stringify(updatedCustomerInfo)
    }
  );
  if (response.status === 401) {
    return forceSignOut();
  }

  if (!response.ok) throw new Error('Internal server error');

  return response.json();
};

export class NotACustomerError extends Error {
  response: Response;

  constructor(response: Response, ...params: []) {
    super(...params);
    this.name = this.constructor.name;
    this.response = response;
    Object.setPrototypeOf(this, NotACustomerError.prototype);
    if (!this.message) {
      this.message = this.response.status.toString();
    }
  }
}
