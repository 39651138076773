import { getValidLocaleMatch } from 'utils/localization/getLocaleMatch';

import { AUTH_KEY } from './AuthProvider';

export const getAuthState = () => {
  const authState = localStorage.getItem(AUTH_KEY);

  if (!authState) return {};

  return JSON.parse(authState);
};

export const forceRemoveAuthState = () => {
  localStorage.removeItem(AUTH_KEY);
};

export const forceSignOut = () => {
  forceRemoveAuthState();
  const localePathname = getValidLocaleMatch(window.location.pathname);
  const url = localePathname
    ? window.location.href + localePathname
    : window.location.href;

  window.location.href = url;
};

const minuteInMs = 60000;

export const getRefreshInterval = (expiresAt: number) => {
  const needtoRefreshAt = expiresAt - minuteInMs * 2;

  const now = new Date().getTime();

  return needtoRefreshAt - now;
};
