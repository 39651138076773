import styled from 'styled-components';

import TeliaFinanceLogo from 'assets/telia-finance-logo-purple.svg';
import TeliaFinanceStacked from 'assets/telia-finance-stacked-logo-purple.svg';

export const NavBar = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 54px;

  @media screen and (min-width: ${({ theme }) =>
      theme.breakpoints.breakpointMd}) {
    height: 80px;
  }

  @media screen and (min-width: ${({ theme }) =>
      theme.localTheme.breakpoints.breakpointLg}) {
    height: 76px;
  }
`;

export const LeftSide = styled.div`
  display: flex;
  align-items: center;
`;

export const RightSide = styled.div`
  display: flex;
  align-items: center;

  & > *:not(:last-child) {
    margin-right: ${({ theme }) => theme.spacing.spacing24};
  }

  @media screen and (min-width: ${({ theme }) =>
      theme.localTheme.breakpoints.breakpointLg}) {
    margin: 33px 0 27px 0;
  }
`;

export const IconMobile = styled.img.attrs({ src: TeliaFinanceStacked })``;

export const IconDesktop = styled.img.attrs({ src: TeliaFinanceLogo })``;

export const TeliaIconWrapper = styled.div`
  ${IconDesktop} {
    display: none;
  }
  height: 48px;
  margin-right: ${({ theme }) => theme.spacing.spacing16};

  @media screen and (min-width: ${(p) => p.theme.breakpoints.breakpointMd}) {
    margin-right: ${({ theme }) => theme.spacing.spacing24};
  }
  @media screen and (min-width: ${({ theme }) =>
      theme.localTheme.breakpoints.breakpointLg}) {
    ${IconMobile} {
      display: none;
    }
    ${IconDesktop} {
      display: initial;
    }
    height: 32px;
    margin: ${({ theme }) =>
      `${theme.spacing.spacing20} ${theme.spacing.spacing48} ${theme.spacing.spacing24} 0`};
  }

  &:hover {
    cursor: pointer;
  }
`;

export const Divider = styled.div`
  height: 24px;
  width: 1px;
  background-color: rgba(0, 0, 0, 0.12);
  @media screen and (min-width: ${({ theme }) =>
      theme.localTheme.breakpoints.breakpointLg}) {
    display: none;
  }
`;

export const MobileMenuWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-left: ${({ theme }) => theme.spacing.spacing16};
  cursor: pointer;
  p {
    display: none;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.breakpointMd}) {
    margin-left: ${({ theme }) => `calc(${theme.spacing.spacing24} + 3px)`};
    display: flex;
    align-items: center;

    p {
      display: initial;
      margin-left: ${({ theme }) => theme.spacing.spacing8};
    }
  }
  @media screen and (min-width: ${({ theme }) =>
      theme.localTheme.breakpoints.breakpointLg}) {
    display: none;
  }
`;

export const DesktopMenuWrapper = styled.div`
  display: none;

  @media screen and (min-width: ${({ theme }) =>
      theme.localTheme.breakpoints.breakpointLg}) {
    display: flex;
    margin: 33px 0 27px 0;
    & > * {
      margin-right: ${({ theme }) => theme.spacing.spacing24};
    }
  }
`;
