import React from 'react';

import useLocale from 'hooks/useLocale';
import { countrySpecificLocales } from 'i18n/supported-locales';

import {
  LanguageItem,
  StyledLanguageSelector
} from './LanguageSelector.styled';

export default function LanguageSelector({ theme = 'light', ...rest }) {
  const { countryCode, localeCode, handleChangeLocale } = useLocale();
  const languageOptions = countrySpecificLocales?.[countryCode];

  if (!languageOptions) return null;

  if (languageOptions?.length <= 1) return null;
  return (
    <StyledLanguageSelector {...rest}>
      {languageOptions?.map((option) => (
        <LanguageItem
          active={option.localeCode === localeCode}
          key={option.localeCode}
          componentTheme={theme}
          data-cy={option.localeCode}
          onClick={() =>
            handleChangeLocale({
              newLocale: option?.localeCode,
              prevLocale: localeCode
            })
          }
        >
          {option.displayCode}
        </LanguageItem>
      ))}
    </StyledLanguageSelector>
  );
}
