export default function wait(
  message: string | null,
  delay: number = 3000
): Promise<void> {
  return new Promise((resolve) => {
    if (message) {
      console.log(message);
    }
    setTimeout(resolve, delay);
  });
}
