import React from 'react';

const calculateTime = (sec) => {
  let minutes = Math.floor(sec / 60);
  let seconds = Math.floor(sec % 60);
  minutes = minutes < 10 ? '0' + minutes : minutes;
  seconds = seconds < 10 ? '0' + seconds : seconds;

  return { minutes, seconds };
};

export default function Timer({ milliSeconds }) {
  const { minutes, seconds } = calculateTime(milliSeconds);

  return (
    <>
      {minutes} : {seconds}
    </>
  );
}
