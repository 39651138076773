import React from 'react';
import { Outlet } from 'react-router-dom';

import Footer from 'components/footer/Footer';
import SimpleMenu from 'components/menu/SimpleMenu';

import { Footer as FooterWrapper, Layout, Main, Nav } from './layout.styled';

export default function SimpleMenuLayout() {
  return (
    <Layout>
      <Nav>
        <SimpleMenu />
      </Nav>

      <Main>
        <Outlet />
      </Main>

      <FooterWrapper>
        <Footer />
      </FooterWrapper>
    </Layout>
  );
}
