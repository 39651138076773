import {
  IconDesktop,
  IconMobile,
  LeftSide,
  NavBar,
  TeliaIconWrapper
} from './Menu.styled';

import React from 'react';
import useAuth from 'auth/useAuth';
import useLocaleNavigate from 'hooks/useLocaleNavigate';

export default function SimpleMenu() {
  const navigate = useLocaleNavigate();
  const { token } = useAuth();
  const to = token ? '/home' : '/';

  return (
    <NavBar>
      <LeftSide>
        <TeliaIconWrapper onClick={() => navigate(to)}>
          <IconMobile />
          <IconDesktop />
        </TeliaIconWrapper>
      </LeftSide>
    </NavBar>
  );
}
