import React, { useEffect, useRef, useState } from 'react';
import { TFunction, useTranslation } from 'react-i18next';
import { Document, Page, pdfjs } from 'react-pdf';
import { TeliaButton, TeliaIcon, TeliaP } from '@teliads/components/react';
import { error } from '@teliads/icons';

import ButtonWrapper from 'components/ButtonWrapper';
import Loading, { LoadingContainer } from 'components/loading/Loading';
import { registerModal } from 'components/modal';
import Spacing from 'components/Spacing';

import { StyledModal } from './PdfViewerModal.styled';

import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  'pdfjs-dist/build/pdf.worker.min.js',
  import.meta.url
).toString();

const documentOptions = {
  standardFontDataUrl:
    'https://cdn.voca.teliacompany.com/fonts/TeliaSansV10/WOFF2/TeliaSans-Regular.woff2'
};

const PdfViewerModal = ({
  preAuthenticatedFileUrl,
  downloadName
}: {
  preAuthenticatedFileUrl: string;
  downloadName: string;
}) => {
  const { t } = useTranslation();
  const [numPages, setNumPages] = useState(null);
  const [isError, setIsError] = useState(false);
  const [width, setWidth] = useState(0);
  const containerRef = useRef<HTMLDivElement | null>(null);

  const onLoad = ({ numPages }) => {
    setNumPages(numPages);
  };

  useEffect(() => {
    setWidth(containerRef?.current?.clientWidth || 0);
  }, [containerRef]);

  return (
    <StyledModal isError={isError} data-cy="pdfViewerModal">
      <div className="height-restriction">
        <div className="pdf-utility">
          <ButtonWrapper>
            <a
              href={preAuthenticatedFileUrl}
              download={downloadName || 'telia-finance-document'}
              data-cy="download-pdf"
            >
              <TeliaButton variant="secondary" disabled={isError}>
                <span>
                  {t('common.download', { defaultValue: 'Download' })}
                </span>
              </TeliaButton>
            </a>
          </ButtonWrapper>
        </div>

        <div className="outline" ref={containerRef}>
          <Document
            options={documentOptions}
            file={preAuthenticatedFileUrl}
            onLoadSuccess={onLoad}
            error={() => (
              <ErrorComponent t={t} handleError={() => setIsError(true)} />
            )}
            externalLinkRel="_blank"
            externalLinkTarget="_blank"
            loading={
              <LoadingContainer>
                <Loading />
              </LoadingContainer>
            }
          >
            {Array.from(new Array(numPages), (_, index) => (
              <Page
                key={`page_${index + 1}`}
                pageNumber={index + 1}
                renderTextLayer={false}
                renderAnnotationLayer={true}
                renderForms={false}
                width={width}
              />
            ))}
          </Document>
        </div>
      </div>
    </StyledModal>
  );
};

registerModal('pdfViewerModal', PdfViewerModal);

const ErrorComponent = ({
  t,
  handleError
}: {
  t: TFunction;
  handleError: () => void;
}) => {
  useEffect(() => {
    handleError();
  }, []);
  return (
    <div className="error">
      <Spacing mr="32" ml="32">
        <TeliaIcon svg={error.svg} size="lg" />
        <TeliaP>
          <b>
            {t('document.document_error', {
              defaultValue: 'Oops! Something went wrong'
            })}
          </b>
        </TeliaP>
        <TeliaP>
          {t('document.document_error_description', {
            defaultValue:
              'This is taking too long. Don’t worry, try reloading the page.'
          })}
        </TeliaP>
      </Spacing>
    </div>
  );
};
