import { useContext } from 'react';
import { FeatureFlags } from 'features/FeatureFlagsProvider';

export default function useFeatureFlag() {
  const context = useContext(FeatureFlags);

  if (context === undefined) {
    throw Error('No featureflag context found');
  }

  return context;
}
