import React from 'react';
import { useTranslation } from 'react-i18next';

import { LegalLinks } from 'components/link';
import { useModalController } from 'components/modal';

import useLocale from 'hooks/useLocale';
import {
  aboutUsLink,
  privacyPolicyLink
} from 'utils/localization/external-links';

import { FooterLegalWrapper, FooterLinksWrapper } from '../Footer.styled';

export default function FooterLegal() {
  const { t } = useTranslation();
  const { languageCode } = useLocale();

  const { open } = useModalController();
  return (
    <FooterLegalWrapper>
      <p className="copyright">
        © Telia Finance AB 556404-6661, Stjärntorget 1 Solna
      </p>
      <FooterLinksWrapper>
        <LegalLinks href={privacyPolicyLink(languageCode)} target="_blank">
          {t('footer.privacy-policy', { defaultValue: 'Privacy policy' })}
        </LegalLinks>
        <LegalLinks
          onClick={() => open('cookieSettingsModal')}
          data-cy="openCookieSettings"
        >
          {t('footer.cookies', { defaultValue: 'Cookies' })}
        </LegalLinks>
        <LegalLinks href={aboutUsLink(languageCode)} target="_blank">
          {t('footer.about', { defaultValue: 'About us' })}
        </LegalLinks>
      </FooterLinksWrapper>
    </FooterLegalWrapper>
  );
}
