import React, { createContext, ReactNode, useCallback, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import useAuth from 'auth/useAuth';
import getCountryCodeFromLocale from 'utils/localization/getCountryCodeFromLocale';
import getLanguageCodeFromLocale from 'utils/localization/getLanguageCodeFromLocale';
import {
  getIsCountrySpecificValidLocalMatch,
  getValidLocaleMatch
} from 'utils/localization/getLocaleMatch';

import useLocaleCode from '../../hooks/useLocaleCode';

import { CountryCodes, LocaleProviderContext, Locales } from './types';

const LocaleContext = createContext<LocaleProviderContext | undefined>(
  undefined
);

function LocaleProvider({ children }: { children: ReactNode }) {
  const { localeCode, clearLocale } = useLocaleCode();
  const { country: tokenCountryCode, token } = useAuth();
  const { pathname, search } = useLocation();
  const navigate = useNavigate();

  const resetLoginLocale = () => {
    clearLocale();
    return navigate('/', { replace: true });
  };

  const setLoginCountry = useCallback(
    (countryCode: CountryCodes) => {
      const defaultLocale = determineDefaultLocale(countryCode);
      return navigate(defaultLocale, { replace: true });
    },
    [determineDefaultLocale]
  );

  const handleChangeLocale = ({
    newLocale,
    prevLocale
  }: {
    newLocale: Locales;
    prevLocale: Locales | string | null;
  }) => {
    const path = pathname === '/' ? '' : pathname;
    if (!prevLocale) {
      return navigate(newLocale + path + search);
    }

    return navigate(pathname.replace(prevLocale, newLocale) + search, {
      replace: true
    });
  };

  /* Locale sync if logged in (countryCode is set in token) */
  useEffect(() => {
    if (!tokenCountryCode) return;

    const isValid = getIsCountrySpecificValidLocalMatch({
      pathname,
      countryCode: tokenCountryCode
    });

    if (!isValid) {
      handleChangeLocale({
        newLocale: determineDefaultLocale(
          tokenCountryCode.toLowerCase() as CountryCodes
        ),
        prevLocale: getValidLocaleMatch(pathname)
      });
    }
  }, [pathname, tokenCountryCode]);

  /* Locale sync if not logged in (no token) */
  useEffect(() => {
    if (token) return;
    const localeCodeFromPath = getValidLocaleMatch(pathname);

    if (!localeCodeFromPath && localeCode) {
      const path = pathname === '/' ? '' : pathname;
      return navigate(localeCode + path + search, { replace: true });
    }
  }, []);

  return (
    <LocaleContext.Provider
      value={{
        localeCode,
        resetLoginLocale,
        countryCode: tokenCountryCode || getCountryCodeFromLocale(localeCode),
        setLoginCountry,
        handleChangeLocale,
        languageCode: getLanguageCodeFromLocale(localeCode)
      }}
    >
      {children}
    </LocaleContext.Provider>
  );
}

export { LocaleContext, LocaleProvider };

const determineDefaultLocale = (countryCode: CountryCodes): Locales => {
  switch (countryCode) {
    case 'se':
      return 'sv-se';
    case 'dk':
      return 'da-dk';
    case 'fi':
      return 'fi-fi';
    case 'no':
      return 'nb-no';
    default:
      return '' as Locales;
  }
};
