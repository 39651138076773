import React from 'react';
import { useTranslation } from 'react-i18next';
import { TeliaIcon } from '@teliads/components/react';
import { close } from '@teliads/icons';
import useAuth from 'auth/useAuth';

import { SecondaryMenuLogoutLink } from 'components/link';

import { LogoutOverlay, Overlay } from './Overlays.styled';

export default function SignoutOverlay({ closeOverlay }) {
  const { t } = useTranslation();
  const { signOut } = useAuth();

  return (
    <Overlay>
      <LogoutOverlay>
        <SecondaryMenuLogoutLink
          onClick={() => signOut()}
          data-cy="sign-out-overlay-button"
        >
          <p>{t('menu.sign-out', { defaultValue: 'Sign out' })}</p>
        </SecondaryMenuLogoutLink>
        <TeliaIcon svg={close.svg} size="lg" onClick={closeOverlay} />
      </LogoutOverlay>
    </Overlay>
  );
}
