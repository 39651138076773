import { createGlobalStyle, css } from 'styled-components';

const GlobalStyle = createGlobalStyle`
* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}
body{
  background: ${({ theme }) => theme.colors.gray50};
  overflow-y:auto;
  overflow-x:hidden;
  ${({ activeModal }) =>
    activeModal &&
    css`
      overflow: hidden;
    `}
}
#root{
  position:relative;
  
}
:root {
  --purpur-rescale:1;
}

.fixed-modal-width {
  width:400px
}
`;
export default GlobalStyle;
