import { DEFAULT_EVENTS, useIdleTimer } from 'react-idle-timer';
import React from 'react';

import useAuth from 'auth/useAuth';
import { useModalController } from 'components/modal';

/* 
  After 15min (timeout) of in activity the user will get logged out
  When it's 1min (promptTimeout) left the user will be prompted a countdown

  timeout: Total time in milliseconds that the user can be inactive
  promptTimeout: How much time left of inactivity before we prompt the user

*/
const timeout = 1000 * 60 * 15;
const promptTimeout = 1000 * 60;

export default function ActivityTimer() {
  const { signOut } = useAuth();

  const onPrompt = async () => {
    await openModal('idlePromptModal', {
      reset,
      getRemainingTime,
      isPrompted,
      initialPromptTimeout: promptTimeout
    });
  };

  const onIdle = () => {
    close('idlePromptModal');
    signOut();
  };

  const { getRemainingTime, isPrompted, reset } = useIdleTimer({
    timeout,
    promptBeforeIdle: promptTimeout,
    onPrompt,
    onIdle,
    element: document,
    events: DEFAULT_EVENTS,
    eventsThrottle: 200
  });

  const { open: openModal, close } = useModalController();

  return <></>;
}
