import { useContext } from 'react';

import { AuthContext } from './AuthProvider';
import { AuthProviderContext } from './types';

export default function useAuth(): AuthProviderContext {
  const context = useContext(AuthContext);

  if (context === undefined) {
    throw Error('No auth context found');
  }

  return context;
}
