import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TeliaIcon } from '@teliads/components/react';
import { endUser, menu } from '@teliads/icons';
import useAuth from 'auth/useAuth';
import useGetLocalePath from 'hooks/useGetLocalePath';
import useLocaleNavigate from 'hooks/useLocaleNavigate';
import { useBreakpoint } from 'providers/BreakpointProvider';

import { MenuLink, SecondaryMenuLink } from 'components/link';

import MobileMenuOverlay from './MobileMenuOverlay/MobileMenuOverlay';
import {
  DesktopMenuWrapper,
  Divider,
  IconDesktop,
  IconMobile,
  LeftSide,
  MobileMenuWrapper,
  NavBar,
  RightSide,
  TeliaIconWrapper
} from './Menu.styled';
import SecondaryMenu from './SecondaryMenu';
import useNavRoutes from './useNavRoutes';

export const OVERLAY_TYPES = { MENU: 'MENU', LOGOUT: 'LOGOUT' };

export default function Menu() {
  const [isOverLayOpen, setIsOverlayOpen] = useState(null);
  const breakpoints = useBreakpoint();
  const { signOut } = useAuth();
  const { t } = useTranslation();
  const navigate = useLocaleNavigate();
  const { routes } = useNavRoutes();
  const getLocalePath = useGetLocalePath();

  return (
    <div>
      <NavBar>
        <LeftSide>
          <TeliaIconWrapper onClick={() => navigate('home')}>
            <IconMobile />
            <IconDesktop />
          </TeliaIconWrapper>
          <Divider />
          <MobileMenuWrapper
            onClick={() => setIsOverlayOpen(OVERLAY_TYPES.MENU)}
            data-cy="menuOverlayToggle"
          >
            <TeliaIcon svg={menu.svg} size="md" />
            <p>{t('menu.menu', { defaultValue: 'Menu' })}</p>
          </MobileMenuWrapper>
          <DesktopMenuWrapper data-cy="menuRow">
            {routes?.map((link) => (
              <MenuLink to={getLocalePath(link.to)} key={link.to}>
                {link.text}
              </MenuLink>
            ))}
          </DesktopMenuWrapper>
        </LeftSide>
        <RightSide>
          <SecondaryMenuLink
            data-cy="sign-out-menu"
            onClick={
              breakpoints?.localLarge
                ? () => signOut()
                : () => setIsOverlayOpen(OVERLAY_TYPES.LOGOUT)
            }
          >
            <TeliaIcon svg={endUser.svg} size="md" />
            <p>{t('menu.sign-out', { defaultValue: 'Sign out' })}</p>
          </SecondaryMenuLink>
        </RightSide>

        <MobileMenuOverlay
          isOverLayOpen={isOverLayOpen}
          setIsOverlayOpen={setIsOverlayOpen}
        />
      </NavBar>
      <SecondaryMenu />
    </div>
  );
}
