import React from 'react';
import { Route, Routes } from 'react-router-dom';
import AuthGuard from 'auth/AuthGuard';
import useFeatureFlag from 'hooks/useFeatureFlag';
import SelfClosePage from 'pages/SelfClosePage';

import CommonLayout from 'components/layouts/CommonLayout';
import FullBleedLayout from 'components/layouts/FullBleedLayout';
import LoginLayout from 'components/layouts/LoginLayout';
import SimpleMenuLayout from 'components/layouts/SimpleMenuLayout';
import VerifyEmailLayout from 'components/layouts/VerifyEmailLayout';
import { withLazyLoad } from 'components/layouts/withLazyLoad';

/* Main Route */
const LoginPage = withLazyLoad(
  React.lazy(() => import('pages/login/LoginPage'))
);

/* Main-route */
const NoCustomerPage = withLazyLoad(
  React.lazy(() => import('pages/not-a-customer/NotACustomerPage'))
);

/* Main Route */
const CookieInfoPage = withLazyLoad(
  React.lazy(() => import('pages/cookie-info/CookieInfoPage'))
);

const UserTermsPage = withLazyLoad(
  React.lazy(() => import('pages/user-terms/UserTermsPage'))
);

/* Main Route */
const HomePage = withLazyLoad(React.lazy(() => import('pages/home/HomePage')));

/* Main Route */
const MyInvoicePage = withLazyLoad(
  React.lazy(() => import('pages/my-invoice/MyInvoicePage'))
);
/* Sub route to my-invoice */
const AllTransactionsPage = withLazyLoad(
  React.lazy(
    () =>
      import('pages/my-invoice/sub-pages/all-transactions/AllTransactionsPage')
  )
);
/* Sub route to my-invoice */
const PayExtraPage = withLazyLoad(
  React.lazy(() => import('pages/my-invoice/sub-pages/pay-extra/PayExtraPage'))
);

/* Main Route */
const MyAccountPage = withLazyLoad(
  React.lazy(() => import('pages/my-account/MyAccountPage'))
);

/* Main Route */
const DocumentsPage = withLazyLoad(
  React.lazy(() => import('pages/documents/DocumentsPage'))
);

/* Main Route */
const SettingsPage = withLazyLoad(
  React.lazy(() => import('pages/settings/SettingsPage'))
);

/* Main Route */
const SupportPage = withLazyLoad(
  React.lazy(() => import('pages/support/SupportPage'))
);

/* Main Route */
const EmailVerificationPage = withLazyLoad(
  React.lazy(() => import('pages/email-verification/EmailVerificationPage'))
);

/* 404 Route */
const NotFoundPage = withLazyLoad(React.lazy(() => import('pages/404/404')));

export default function UniportalRoutes() {
  const { enablePayments, kyc, emailVerification } = useFeatureFlag();

  return (
    <Routes>
      <Route element={<LoginLayout />}>
        <Route path="/:lang?" element={<LoginPage />} />
        <Route path={`/:lang?/notacustomer`} element={<NoCustomerPage />} />
      </Route>

      <Route element={<VerifyEmailLayout />}>
        {emailVerification && (
          <Route
            path="/:lang?/verifyEmail"
            element={<EmailVerificationPage />}
          />
        )}
      </Route>
      <Route element={<CommonLayout />}>
        <Route
          path="/:lang/home"
          element={
            <AuthGuard>
              <HomePage />
            </AuthGuard>
          }
        />

        <Route path="/:lang/my-invoices">
          <Route
            index
            element={
              <AuthGuard>
                <MyInvoicePage />
              </AuthGuard>
            }
          />
          <Route
            path="all-transactions"
            element={
              <AuthGuard>
                <AllTransactionsPage />
              </AuthGuard>
            }
          />
          {enablePayments && (
            <Route
              path="pay-extra"
              element={
                <AuthGuard>
                  <PayExtraPage />
                </AuthGuard>
              }
            />
          )}
        </Route>

        <Route
          path="/:lang/my-account"
          element={
            <AuthGuard>
              <MyAccountPage />
            </AuthGuard>
          }
        />
        <Route
          path="/:lang/documents"
          element={
            <AuthGuard>
              <DocumentsPage />
            </AuthGuard>
          }
        />
        <Route
          path="/:lang/settings"
          element={
            <AuthGuard>
              <SettingsPage />
            </AuthGuard>
          }
        />

        <Route
          path="/:lang/support"
          element={
            <AuthGuard>
              <SupportPage />
            </AuthGuard>
          }
        />

        <Route
          path="/:lang/*"
          element={
            <AuthGuard>
              <NotFoundPage />
            </AuthGuard>
          }
        />
      </Route>

      <Route element={<SimpleMenuLayout />}>
        <Route path="/:lang?/cookie-info" element={<CookieInfoPage />} />
        <Route path="/:lang?/user-terms" element={<UserTermsPage />} />
        <Route path="/:lang?/self-close" element={<SelfClosePage />} />
      </Route>
    </Routes>
  );
}
