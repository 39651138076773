import { forceSignOut } from 'auth/AuthHelpers';
import poll from 'utils/polling';

import { NotACustomerError } from '../customer-information';

import {
  AuthCancel,
  AuthPoll,
  AuthPollResponse,
  AuthRefreshResponse,
  AuthStart,
  AuthStartResponse,
  SbidAuthPoll,
  SbidAuthPollResponse,
  SbidAuthStartResponse
} from './types';

export const authStart = async ({
  countryCode,
  redirectUrl
}: AuthStart): Promise<AuthStartResponse> => {
  const response = await fetch(
    `${
      process.env.UNIPORTAL_BACKEND
    }/v1/auth/start/${undefined}?redirectUrl=${redirectUrl}&countryCode=${countryCode.toUpperCase()}`,
    {
      method: 'POST',
      headers: {
        'Content-type': 'application/json'
      }
    }
  );

  if (response.status !== 200) throw new Error();

  return response.json();
};

export const sbidAuthStart = async (): Promise<SbidAuthStartResponse> => {
  const response = await fetch(
    `${process.env.UNIPORTAL_BACKEND}/v1/auth/sbid/start`,
    {
      method: 'POST',
      headers: {
        'Content-type': 'application/json'
      }
    }
  );

  if (response.status !== 200) throw new Error();

  return await response.json();
};

export const authPoll = async ({
  countryCode,
  token
}: AuthPoll): Promise<AuthPollResponse | null> => {
  const pollFn = () =>
    fetch(
      `${process.env.UNIPORTAL_BACKEND}/v1/auth/poll?token=${token}&countryCode=${countryCode}`,
      {
        method: 'GET',
        headers: {
          'Content-type': 'application/json'
        }
      }
    );

  const response = await poll({
    fn: pollFn,
    fnCondition: (response) => response.status === 202,
    delay: 2000,
    pollingMessage: 'Waiting for authentication'
  });

  if (response.status === 410) return null;
  if (response.status === 404) throw new NotACustomerError(response);
  if (response.status >= 400) {
    throw new Error();
  }

  return response.json();
};

export const sbidAuthPollFn = async ({
  token
}: SbidAuthPoll): Promise<SbidAuthPollResponse | null> => {
  const response = await fetch(
    `${process.env.UNIPORTAL_BACKEND}/v1/auth/sbid/poll?token=${token}`,
    {
      method: 'GET',
      headers: {
        'Content-type': 'application/json'
      }
    }
  );

  if (response.status === 410) return null;
  if (response.status === 404) throw new NotACustomerError(response);
  if (response.status >= 400) {
    throw new Error();
  }

  return response.json();
};

export const authCancel = async ({ token }: AuthCancel): Promise<void> => {
  const response = await fetch(
    `${process.env.UNIPORTAL_BACKEND}/v1/auth/cancel?token=${token}`,
    {
      method: 'POST',
      headers: {
        'Content-type': 'application/json'
      }
    }
  );
  if (response.status !== 204) throw new Error();
};

export const sbidAuthCancel = async ({ token }: AuthCancel): Promise<void> => {
  const response = await fetch(
    `${process.env.UNIPORTAL_BACKEND}/v1/auth/sbid/cancel?token=${token}`,
    {
      method: 'GET',
      headers: {
        'Content-type': 'application/json'
      }
    }
  );
  if (response.status !== 204) throw new Error();
};

export const authRefresh = async (
  token: string
): Promise<AuthRefreshResponse | void> => {
  const response = await fetch(
    `${process.env.UNIPORTAL_BACKEND}/v1/auth/refreshtoken`,
    {
      method: 'POST',
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    }
  );

  if (response.status !== 200) {
    return forceSignOut();
  }

  return response.json();
};
