import { useNavigate } from 'react-router-dom';

import useGetLocalePath from './useGetLocalePath';

export default function useLocaleNavigate() {
  const navigate = useNavigate();
  const getLocalePath = useGetLocalePath();

  return (path, options) => {
    return navigate(getLocalePath(path), options);
  };
}
