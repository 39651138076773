import styled from 'styled-components';

const ButtonWrapper = styled.div`
  margin-top: ${({ theme }) => theme.spacing.spacing8};

  & > *:first-child {
    margin-bottom: ${({ theme }) => theme.spacing.spacing16};
    margin-right: ${({ theme }) => theme.spacing.spacing8};
  }
  & > *:first-child > button {
    margin-bottom: ${({ theme }) => theme.spacing.spacing16};
    margin-right: ${({ theme }) => theme.spacing.spacing8};
  }

  @media screen and (min-width: ${({ theme }) =>
      theme.breakpoints.breakpointMd}) {
    display: flex;

    & > *:first-child {
      margin-bottom: 0;
    }
    & > *:first-child > button {
      margin-bottom: 0;
    }
  }
`;

export default ButtonWrapper;
