import { useRecoilState } from 'recoil';

import { currentModal } from './modalAtom';

export const modals = {};
let promise = null;

export const getModalList = () => modals;

export function registerModal(modalName, modalComponent) {
  if (modals[modalName]) {
    throw new MultipleModalRegistrationError(modalName);
  }
  modals[modalName] = modalComponent;
}

export const useModalController = () => {
  const [, setModal] = useRecoilState(currentModal);

  return {
    open: (modalName, data) => {
      setModal({ modalName, data });
      return new Promise((resolve, reject) => {
        promise = { resolve, reject };
      });
    },
    close: (data, resolve = true) => {
      setModal();
      return resolve ? promise.resolve(data) : promise.resolve(null);
    }
  };
};

class MultipleModalRegistrationError extends Error {
  constructor(name) {
    super();
    this.message = `Modal (${name}) already registered`;
    this.name = 'MultipleModalRegistrationError';
    Object.setPrototypeOf(this, MultipleModalRegistrationError.prototype);
  }
}
