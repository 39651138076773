import React from 'react';
import { CSSProperties } from 'react';
import { colors, motion } from '@teliads/components/foundations';
import styled, { keyframes } from 'styled-components';

const Loading = ({
  color,
  ...rest
}: {
  color?: CSSProperties['color'];
  [key: string]: unknown;
}) => {
  return (
    <LoadingWrapper data-cy="Loading" {...rest}>
      <Circle color={color} />
      <Circle color={color} />
      <Circle color={color} />
    </LoadingWrapper>
  );
};

const Bounce = keyframes`
  0%,
  60%,
  100% {
    transform: translateY(0px);
  }
  30% {
    transform: translateY(-62.5%);
  }
`;

const Circle = styled.div.attrs({ className: 'Circle' })<{
  color?: CSSProperties['color'];
}>`
  width: 24%;
  margin-top: 16%;
  background-color: ${(p) => (p.color ? p.color : colors.purple500)};
  border-radius: 100%;
  display: inline-block;
  animation: ${Bounce} ${`${motion.duration1500}`} infinite ease-in-out both;

  &:nth-child(1) {
    animation-delay: ${`-${motion.duration300}`};
  }

  &:nth-child(2) {
    animation-delay: ${`-${motion.duration100}`};
  }

  &:after {
    content: '';
    display: block;
    padding-bottom: 100%;
  }
`;

const LoadingWrapper = styled.div.attrs({ className: 'LoadingWrapper' })`
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: ${({ theme }) => theme.spacing.spacing4};
`;

export const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;

  & div {
    width: 50px;
    height: auto;
  }
`;

export default Loading;
