import styled, { keyframes } from 'styled-components';

const rotate = keyframes`100% {transform: rotate(360deg)}`;

const clip = keyframes` 
0%   {clip-path:polygon(50% 50%,0 0,0 0,0 0,0 0,0 0)}
25%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 0,100% 0,100% 0)}
50%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,100% 100%,100% 100%)}
75%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,0 100%,0 100%)}
100% {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,0 100%,0 0)}`;

export const StyledSpinner = styled.div`
  width: ${({ size }) => `${size}px`};
  height: ${({ size }) => `${size}px`};
  border-radius: 50%;
  position: relative;
  animation: ${rotate} ${({ theme }) => theme.motion.duration1500} linear
    infinite;

  &:before {
    content: '';
    box-sizing: border-box;
    position: absolute;
    inset: 0px;
    border-radius: 50%;
    border: ${({ thickness }) => `${thickness}px`} solid
      ${({ theme }) => theme.colors.purple500};
    animation: ${clip} ${({ theme }) => theme.motion.duration2000} linear
      infinite;
  }
`;
