import { CookieContext } from 'providers/cookie-provider/CookieProvider';
import { ICookieProvider } from 'providers/cookie-provider/types';
import { useContext } from 'react';

export default function useCookies(): ICookieProvider {
  const context = useContext(CookieContext);
  if (context === undefined) {
    throw Error('No cookie context found');
  }
  return context;
}
