import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import {
  TeliaButton,
  TeliaHeading,
  TeliaIcon,
  TeliaP
} from '@teliads/components/react';
import { checkCircleFilled, error } from '@teliads/icons';
import { sendFeedback } from 'api/mail';
import { getCustomerInformationQuery } from 'queries/customerInformationQuery';
import { useTheme } from 'styled-components';
import styled from 'styled-components';
import { required } from 'utils/validation/required';

import { InjectColor } from 'components/file-handling/files.styled';
import TextArea from 'components/form/TextArea';
import Spacing from 'components/Spacing';

import Modal from '../Modal';
import { registerModal } from '../ModalController';

import { MessageWrapper, TextAreaNoResize } from './ProvideFeedback.styles';

const StyledFeedbackModal = styled(Modal)`
  max-width: 500px;
`;

const ProvideFeedbackModal = () => {
  const { t } = useTranslation();
  const {
    handleSubmit,
    control,
    formState: { isValid, isDirty }
  } = useForm({ mode: 'onChange', reValidateMode: 'onChange' });

  const [modalState, setModalState] = useState('Initial');
  const theme = useTheme();

  const { data: customerInfo } = useQuery(getCustomerInformationQuery());

  const handleSubmitFeedback = async ({ feedback }) => {
    try {
      (await sendFeedback({
        feedback,
        panAccountNumber: customerInfo?.panAccountNumber
      }))
        ? setModalState('Success')
        : setModalState('Error');
    } catch {
      setModalState('Error');
    }
  };

  return (
    <StyledFeedbackModal>
      {modalState === 'Initial' && (
        <Spacing>
          <TeliaHeading variant="title-400" tag="h2">
            {t('provide_feedback', { defaultValue: 'Provide feedback' })}
          </TeliaHeading>
          <Spacing mt="16" mb="20">
            <TeliaP variant="paragraph-100">
              <Trans
                i18nKey="provide_feedback.description"
                defaults="We’re always looking for ways to improve! <br/>
              Share your experience with us."
                components={{ br: <br /> }}
              />
            </TeliaP>
          </Spacing>

          <form
            onSubmit={handleSubmit(handleSubmitFeedback)}
            id="provide-feedback-form"
          >
            <TextAreaNoResize>
              <TextArea
                name="feedback"
                maxlength={300}
                additional={t('provide_feedback.max_characters', {
                  defaultValue: 'Max {{number}} characters',
                  number: 300
                })}
                control={control}
                rules={{ validate: required }}
              />
            </TextAreaNoResize>

            <Spacing mb="48" mt="32">
              <TeliaButton
                type="submit"
                variant="primary"
                disabled={!isDirty || !isValid}
              >
                <span>
                  {t('provide_feedback.submit', { defaultValue: 'Send' })}
                </span>
              </TeliaButton>
            </Spacing>
          </form>
        </Spacing>
      )}
      {modalState === 'Success' && (
        <MessageWrapper>
          <Spacing mr="32" ml="32">
            <InjectColor color={theme?.colors?.green700}>
              <TeliaIcon svg={checkCircleFilled.svg} size="lg" />
            </InjectColor>
            <TeliaP>
              <b>
                {t('provide_feedback.feedback_sent', {
                  defaultValue: 'Feedback sent!'
                })}
              </b>
            </TeliaP>
            <TeliaP>
              {t('provide_feedback.feedback_sent_description', {
                defaultValue: 'Thank you for sharing your experience with us.'
              })}
            </TeliaP>
          </Spacing>
        </MessageWrapper>
      )}
      {modalState === 'Error' && (
        <MessageWrapper>
          <Spacing mr="32" ml="32">
            <TeliaIcon svg={error.svg} size="lg" />
            <TeliaP>
              <b>
                {t('provide_feedback.feedback_error', {
                  defaultValue: 'Sorry, we couldn’t send your feedback.'
                })}
              </b>
            </TeliaP>
            <TeliaP>
              {t('provide_feedback.feedback_error_description', {
                defaultValue:
                  'Check your internet connection or try reloading the page.'
              })}
            </TeliaP>
          </Spacing>
        </MessageWrapper>
      )}
    </StyledFeedbackModal>
  );
};

registerModal('provideFeedbackModal', ProvideFeedbackModal);
