import React from 'react';
import { createRoot } from 'react-dom/client';
import { QueryClient, QueryClientProvider } from 'react-query';
import { BrowserRouter } from 'react-router-dom';
import { AuthProvider } from 'auth/AuthProvider';
import { initGtm } from 'features/analytics';
import { FeatureFlagsProvider } from 'features/FeatureFlagsProvider';
import { BreakpointProvider } from 'providers/BreakpointProvider';
import { CookieProvider } from 'providers/cookie-provider/CookieProvider';
import { LocaleProvider } from 'providers/locale-provider/LocaleProvider';
import { RecoilRoot } from 'recoil';
import { ThemeProvider } from 'styled-components';
import transformSizeUnitsToMediaQueries from 'utils/transformSizeUnitsToMediaQueries';

import '@purpurds/purpur/styles';

import localTheme from './theme/localTheme';
import vocaTheme from './theme/voca';
import App from './App';

import '@teliads/components/dist/telia-component-library/telia-component-library.css';

if (JSON.parse(process.env.ENABLE_ANALYTICS as string)) {
  const GTM_KEY = process.env.GTM_KEY as string;
  initGtm(GTM_KEY);
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      staleTime: 1000 * 60 * 15,
      cacheTime: 1000 * 60 * 15,
      retry: 3,
      retryDelay: 1000
    }
  }
});

createRoot(document.getElementById('root') as HTMLDivElement).render(
  <RecoilRoot>
    <BrowserRouter>
      <ThemeProvider theme={{ ...vocaTheme, localTheme }}>
        <BreakpointProvider
          queries={transformSizeUnitsToMediaQueries({
            sm: vocaTheme?.breakpoints?.breakpointSm,
            md: vocaTheme?.breakpoints?.breakpointMd,
            lg: vocaTheme?.breakpoints?.breakpointLg,
            xl: vocaTheme?.breakpoints?.breakpointXl,
            localLarge: localTheme?.breakpoints.breakpointLg
          })}
        >
          <QueryClientProvider client={queryClient}>
            <CookieProvider>
              <AuthProvider>
                <LocaleProvider>
                  <FeatureFlagsProvider>
                    <App />
                  </FeatureFlagsProvider>
                </LocaleProvider>
              </AuthProvider>
            </CookieProvider>
          </QueryClientProvider>
        </BreakpointProvider>
      </ThemeProvider>
    </BrowserRouter>
  </RecoilRoot>
);
