import { initReactI18next } from 'react-i18next';
import i18n from 'i18next';
import PhraseInContextEditorPostProcessor from 'i18next-phrase-in-context-editor-post-processor';

import da from './translations/da.json';
import en from './translations/en.json';
import enFI from './translations/en-FI.json';
import enNO from './translations/en-NO.json';
import fi from './translations/fi.json';
import no from './translations/no.json';
import sv from './translations/sv.json';
import svFI from './translations/sv-FI.json';

const search = window?.location?.search || '';
const { phrase } = Object.fromEntries(new URLSearchParams(search)) ?? {};
const isTranslationMode = process.env.isTranslationMode === 'true' && phrase;

const resources = {
  en: { translation: en },
  sv: { translation: sv },
  da: { translation: da },
  fi: { translation: fi },
  nb: { translation: no },
  'sv-FI': { translation: svFI },
  'en-FI': { translation: enFI },
  'en-NO': { translation: enNO }
};

i18n
  .use(initReactI18next)
  .use(
    new PhraseInContextEditorPostProcessor({
      phraseEnabled: isTranslationMode,
      projectId: 'ebe7f2ef31cfe88efe9c7c193e363bd2',
      accountId: process.env.PHRASE_ACCOUNT_ID || '',
      useOldICE: true
    })
  )
  .init({
    resources,
    fallbackLng: {
      'sv-FI': ['sv'],
      'en-FI': ['en'],
      'en-NO': ['en']
    },

    returnEmptyString: true,
    interpolation: {
      escapeValue: false
    },
    postProcess: ['phraseInContextEditor']
  });

export default i18n;
