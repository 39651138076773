import styled from 'styled-components';

import { Modal } from 'components/modal';

export const StyledModal = styled(Modal)`
  padding: ${({ theme }) => theme.spacing.spacing20};
  telia-heading {
    margin-bottom: ${(p) => p.theme.spacing.spacing8};
  }

  telia-p {
    margin-bottom: ${(p) => p.theme.spacing.spacing32};
  }

  form {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(2, 96px) auto;
  }

  @media screen and (min-width: ${({ theme }) =>
      theme.breakpoints.breakpointMd}) {
    .formWrapper {
      width: 80%;
    }
  }
  @media screen and (min-width: ${({ theme }) =>
      theme.breakpoints.breakpointLg}) {
    padding: ${({ theme }) => theme.spacing.spacing24};
    form {
      grid-template-rows: repeat(2, 72px) auto;
      grid-row-gap: ${({ theme }) => theme.spacing.spacing32};
    }
  }
`;
