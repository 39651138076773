import React, { MouseEvent } from 'react';
import { useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { TeliaButton, TeliaHeading, TeliaP } from '@teliads/components/react';
import useCookies from 'hooks/useCookies';
import useLocaleNavigate from 'hooks/useLocaleNavigate';
import { CookieLevels } from 'providers/cookie-provider/types';

import ButtonWrapper from 'components/ButtonWrapper';
import Checkbox from 'components/form/Checkbox';
import { TextLink } from 'components/link';
import { registerModal } from 'components/modal';
import { IModalProps } from 'components/modal/types';

import { StyledModal } from './CookieSettingsModal.styled';

const CookieSettingsModal = ({ closeModal }: IModalProps) => {
  const { t } = useTranslation();
  const navigate = useLocaleNavigate();

  const { acceptCookies, clearCookies, acceptanceLevel } = useCookies();

  const { handleSubmit, control } = useForm();

  const handleSubmitWrapper = ({
    functional,
    analytical
  }: {
    functional: boolean;
    analytical: boolean;
  }) => {
    acceptCookies({
      [CookieLevels.functional]: functional,
      [CookieLevels.analytical]: analytical
    });

    closeModal();
  };

  const handleReject = () => {
    clearCookies();
    closeModal();
  };

  const handleNavigateAndClose = (e: MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    closeModal();
    navigate('cookie-info');
  };

  return (
    <StyledModal hideClose data-cy="cookie-settings">
      <TeliaHeading variant="title-300" tag="h2">
        {t('cookie_consent.title', {
          defaultValue: `We use cookies on our sites`
        })}
      </TeliaHeading>
      <TeliaP>
        <Trans
          i18nKey="cookie_consent.description"
          defaults={
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut id ligula nec libero bibendum accumsan vel vel leo.arcu nec, semper commodo risus. Praesent vel efficitur ante. Quisque venenatis sagittis ipsum, at mattis dolor condimentum id. In sem enim, commodo nec consequat nec, condimentum a nisi. <a>Cookie policy and Cookie table link.</a>'
          }
          components={{
            a: <TextLink onClick={handleNavigateAndClose} />
          }}
        />
      </TeliaP>
      <form
        onSubmit={handleSubmit(handleSubmitWrapper)}
        id="cookie-settings-form"
      >
        <Checkbox
          control={control}
          name="necessaryCookie"
          disabled
          checked
          additional={t('cookie_settings.necessary.description', {
            defaultValue: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut id ligula nec libero bibendum accumsan vel vel leo. arcu nec, semper commodo risus. Praesent vel efficitur ante. Quisque venenatis sagittis ipsum, at mattis dolor.`
          })}
        >
          <b>{t('cookie_settings.necessary', { defaultValue: 'Necessary' })}</b>
        </Checkbox>
        <Checkbox
          control={control}
          checked={acceptanceLevel[CookieLevels.functional]}
          name="functional"
          additional={t('cookie_settings.functional.description', {
            defaultValue: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut id ligula nec libero bibendum accumsan vel vel leo. arcu nec, semper commodo risus. Praesent vel efficitur ante. Quisque venenatis sagittis ipsum, at mattis dolor.`
          })}
        >
          <b>
            {t('cookie_settings.functional', { defaultValue: 'Functional' })}
          </b>
        </Checkbox>
        <Checkbox
          control={control}
          checked={acceptanceLevel[CookieLevels.analytical]}
          name="analytical"
          additional={t('cookie_settings.analytical.description', {
            defaultValue: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut id ligula nec libero bibendum accumsan vel vel leo. arcu nec, semper commodo risus. Praesent vel efficitur ante. Quisque venenatis sagittis ipsum, at mattis dolor.`
          })}
        >
          <b>
            {t('cookie_settings.analytical', { defaultValue: 'Analytical' })}
          </b>
        </Checkbox>

        <ButtonWrapper>
          <TeliaButton fullWidth variant="expressive" type="submit">
            <span>
              {t('cookie_consent.confirm', {
                defaultValue: 'Confirm my choices'
              })}
            </span>
          </TeliaButton>
          <TeliaButton
            fullWidth
            variant="secondary"
            type="button"
            onClick={handleReject}
            data-cy="cookie-settings-reject"
          >
            <span>
              {t('cookie_consent.reject', { defaultValue: 'Reject all' })}
            </span>
          </TeliaButton>
        </ButtonWrapper>
      </form>
    </StyledModal>
  );
};

registerModal('cookieSettingsModal', CookieSettingsModal);
