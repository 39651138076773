import { LocaleContext } from 'providers/locale-provider/LocaleProvider';
import { LocaleProviderContext } from 'providers/locale-provider/types';
import { useContext } from 'react';

export default function useLocale(): LocaleProviderContext {
  const context = useContext(LocaleContext);

  if (context === undefined) {
    throw Error('No locale context found');
  }
  return context;
}
