import React from 'react';
import { Outlet } from 'react-router-dom';

import styled from 'styled-components';

import Footer from 'components/footer/Footer';
import LanguageSelector from 'components/language-selector/LanguageSelector';

import TeliaBackground from 'assets/Telia_5th-Element_Deep-Purple_07.webp';
import { Logo } from 'pages/login/LoginPage.styles';

import { Footer as FooterWrapper, Layout, Main, Nav } from './layout.styled';

const LoginMainBackgroundImg = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  background-color: #2a003e; /* Same color as in background image */

  & img {
    width: 1920px;
    height: 1080px;
    margin-left: -140vw;
    transform: rotate(90deg);
    @media screen and (min-width: ${({ theme }) =>
        theme.breakpoints.breakpointMd}) {
      right: 0;
      width: auto;
      height: 120vh;
      min-height: 667px;
      min-width: 100vw;
      margin-left: 15vw;
      margin-top: 0;
      transform: rotate(0deg);
    }
    @media screen and (min-width: ${({ theme }) =>
        theme.breakpoints.breakpointXl}) {
      height: 170vh;
      margin-left: 25vw;
      margin-top: 0;
    }
  }
`;

const StyledLayout = styled(Layout)`
  position: relative;
  overflow: hidden;
  ${Nav}, ${Main} {
    background: transparent;
    z-index: 2;
  }

  ${Nav} {
    margin-top: ${({ theme }) => theme.spacing.spacing16};
    @media screen and (min-width: ${({ theme }) =>
        theme.breakpoints.breakpointMd}) {
      margin-top: ${({ theme }) => theme.spacing.spacing24};
    }

    @media screen and (min-width: ${({ theme }) =>
        theme.localTheme.breakpoints.breakpointLg}) {
      margin-top: ${({ theme }) => theme.spacing.spacing48};
    }

    @media screen and (min-width: ${({ theme }) =>
        theme.localTheme.breakpoints.breakpointXl}) {
      margin-top: ${({ theme }) => theme.spacing.spacing48};
    }
  }

  ${FooterWrapper} {
    z-index: 2;
  }
  ${LoginMainBackgroundImg} {
    z-index: 1;
  }
`;

const LoginMain = styled(Main)`
  color: ${({ theme }) => theme.colors.purple100};
  margin-bottom: 40px;

  & a {
    color: ${({ theme }) => theme.colors.purple100};
  }

  @media screen and (min-width: ${({ theme }) =>
      theme.localTheme.breakpoints.breakpointLg}) {
    height: calc(100vh - 136px - 82px);
    margin-bottom: 0px;
  }
`;

const LoginMainLogoContainer = styled.div`
  z-index: 2;
`;

const FlexBetween = styled.div`
  display: flex;
  justify-content: space-between;
`;

export default function LoginLayout() {
  return (
    <StyledLayout>
      <LoginMainBackgroundImg>
        <img src={TeliaBackground} alt={''} />
      </LoginMainBackgroundImg>
      <Nav>
        <FlexBetween>
          <LoginMainLogoContainer>
            <Logo />
          </LoginMainLogoContainer>
          <LanguageSelector theme="dark" className="language-selector" />
        </FlexBetween>
      </Nav>

      <LoginMain>
        <Outlet />
      </LoginMain>

      <FooterWrapper>
        <Footer />
      </FooterWrapper>
    </StyledLayout>
  );
}
